// responsive screen values
$xxs: 376px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1901px;

// sidebar width and header height
$sidebar_w: 70px;
$sidebar_w_s: 50px;
$sidebar_lg: 250px;

// screen size when content area will shift to right on expanding sidebar
$pushScreen: 9999px;

// content scroll area (-> card body -> 24px) -> initial -> 144px : has_head -> +27.5 : has_btns -> + 41.03 : has_tab -> + 56 
$initialScroll: 151px; // padding-> T:24px B: 18px, page_title: 26 + 20, main-card padding: T: 24px B: 24px, margin_bottom: 15px
$initialScrollSM: 135px; // padding-> T:24px B: 18px, page_title: 26 + 20, main-card padding: T: 16px B: 16px, margin_bottom: 15px
$mainCardPadding: 48px;
$mainCardPaddingsm: 32px;
$hasHead: 27.5px;
$hasBtn: 36.53px;
$hasTab: 58px;

$pageTitle: 36.53px;

$borderRadius: 5px;

:root {
    // main theme color
    --theme-blue: #44C8F5;
    --theme-blue-rgb: 66, 200, 245;

    --bs-blue-hover: rgba(var(--bs-blue-rgb), .9);
    --bs-body-color: #495057;

    --bs-heading-color: #495057;

    // overwrite bootstrap and theme main color to values assigned to --theme-blue
    --bs-blue: var(--theme-blue);
    --bs-primary: var(--theme-blue);
    --bs-link-color: var(--theme-blue);
    --bs-blue-rgb: var(--theme-blue-rgb);

    // other color veriables
    --theme-black: #334155;
    --text-global: #64748b;
    --dark-text: #495057;
    --bs-border-color: #dfdfdf; // -- #E9E9E9 -> #dfdfdf
    --bs-input-border-color: #ced4da; // -- #E6E6E6 -> #ced4da
    --text-muted: #74788d; // -- #969696 -> #74788d
    --readonly-color: #f0f0f0;

    // default font size for content 
    // --bs-body-font-size: 14px;
    // --bs-font-sans-serif: "Poppins", sans-serif;
}

// .pdf_width {
//     max-width: 870px;
// }

.btn {
    // --bs-btn-font-size: 16px;
    height: $hasBtn;
    --bs-btn-disabled-opacity: .6;

    @media screen and (max-width: ($sm - 1px)) {
        --bs-btn-font-size: 14px;
    }

    &.rounded {
        border-radius: 30px !important;
    }

    &.btn-outline-primary {

        &:active,
        &:focus {
            background: unset;
            color: var(--theme-blue);
        }

        &:hover {
            background: var(--theme-blue);
            color: #ffffff;
        }
    }
}

a {
    &.disabled {
        opacity: .6;
        cursor: auto !important;
    }

    &.nav-link {
        &:hover {
            color: var(--theme-blue);
        }
    }
}

// span.nav-link {
//     &:hover {
//         color: var(--theme-blue);
//         svg {
//             color: var(--theme-blue) !important;
//         }
//     }
// }

.dropdown-item {
    &:hover {
        cursor: pointer;
        color: var(--theme-blue);
    }
}

.border-left {
    border-left: 1px solid var(--bs-border-color);
}

.text-muted {
    color: var(--text-muted) !important;
}

.text-muted-light {
    color: #c2c2c2 !important;
}

.main_buttons {
    align-items: center;

    .btn {
        display: flex;
        align-items: center;
    }
}

body {
    min-height: 100vh;
    overflow-x: hidden;
    // font-family: var(--bs-font-sans-serif);

    #layout-wrapper {
        min-height: 100vh;

        .main-content {
            transition: all .4s ease-in-out;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .page-content {
                min-height: 100vh;
            }

            >.footer {
                padding-left: 94px;
                position: unset;
            }
        }

        &.sidebar-lg {
            .main-content {
                @media screen and (min-width: $pushScreen) {
                    margin-left: calc($sidebar_lg - $sidebar_w);
                }
            }

            .navbar-header {
                .logo_large {
                    display: none;
                }
            }
        }
    }

    &.pdf_view {
        .page-main {

            header,
            .vertical-menu {
                display: none;
            }
        }

        .page-content {
            padding: 18px !important;
            margin-left: 0 !important;

            .quote_pdf.has_x_scroll {
                max-height: 100%;
            }
        }
    }
}

.fw-500 {
    font-weight: 500 !important;
}

.text-small,
small {
    font-size: 12px !important;
}

/* custom scroll bar */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
    background: #e6e6e6;
}

::-webkit-scrollbar-track {
    border-radius: 12px;
}

::-webkit-scrollbar-track:hover {
    background: #e6e6e6;
}

::-webkit-scrollbar-thumb {
    background: rgba(207, 202, 202, 0.7);
    border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(207, 202, 202, 1);
}

.text-dark {
    color: var(--dark-text);
}

.br {
    &-4 {
        border-radius: 4px;
    }

    &-8 {
        border-radius: 8px;
    }
}

.custom_x_gap {
    gap: 0 20px;
}

.page-content {
    margin-left: $sidebar_w;

    @media screen and (max-width: ($sm - 1px)) {
        margin-left: $sidebar_w_s;
        padding-top: 74px;
    }
}

.container-fluid {
    padding: 0 !important;
}

.footer {
    left: $sidebar_w;

    @media screen and (max-width: ($sm - 1px)) {
        left: $sidebar_w_s;
    }
}

.card {
    border-radius: 10px;

    &.shadow-2 {
        box-shadow: 0px 0px 2px rgb(40 41 61 / 4%), 0px 4px 8px rgb(96 97 112 / 16%);
    }

    &.light_border {
        border: 1px solid #ebebeb;
    }

    &.setting_card {
        &:hover {
            box-shadow: 0px 0px 2px rgb(40 41 61 / 4%), 0px 4px 8px rgb(96 97 112 / 16%);
        }
    }

    .card-body:not(.main-card) {
        padding: 1rem;

        @media screen and (max-width: ($sm - 1px)) {
            padding: 12px;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.grey-text {
    color: #959699;
}

input,
button,
.btn,
.form-control,
select {
    outline: none !important;
    box-shadow: none !important;
}

button,
.btn {
    text-wrap: nowrap;
}

.btn-light {
    --bs-btn-hover-bg: #efefef;
}

.d-xs-none {
    @media screen and (min-width: $xs) {
        display: none !important;
    }
}

.d-xs-block {
    @media screen and (min-width: $xs) {
        display: block !important;
    }
}

.clickable,
a {
    cursor: pointer;
}

.form-check-input {
    background-color: var(--bs-input-border-color) !important;

    &:checked {
        color: #fff;
        background-color: var(--theme-blue) !important;
    }
}

.w-7 {
    width: 2rem;
}

.h-7 {
    height: 2rem;
}

.fill-primary {
    fill: var(--theme-blue);
}

.dot {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    margin-right: 6px !important;
    display: inline-block;
}

.logo_large {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 15px;
    width: 100%;
    max-width: 200px;
    height: $sidebar_w;


    @media screen and (max-width: ($sm - 1px)) {
        height: $sidebar_w_s;
        // max-height: $sidebar_w_s;
    }

    img {
        width: unset;
        max-width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: calc($sidebar_w - 15px);

        @media screen and (max-width: ($sm - 1px)) {
            max-height: calc($sidebar_w_s - 15px);
        }
    }
}

// base sidebar with react multi level sidebar
.vertical-menu {
    background-color: var(--theme-blue);
    width: $sidebar_w;
    top: $sidebar_w;
    transition: all .4s ease-in-out;

    @media screen and (max-width: ($sm - 1px)) {
        width: $sidebar_w_s;
        top: $sidebar_w_s;
    }

    #sidebar-menu {
        padding: 15px 10px 0 15px !important;
        background-color: var(--theme-blue);
        height: 100%;

        @media screen and (max-width: ($sm - 1px)) {
            padding: 15px 5px 0 5px !important;
        }

        .multi_level_sidebar {
            max-height: calc(100vh - 110px);
            overflow-y: auto;
            overflow-x: hidden;
            margin-right: -6px;
            padding-right: 6px;

            /* custom scroll bar */
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }

            &::-webkit-scrollbar-corner,
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-track {
                border-radius: 12px;
            }

            &::-webkit-scrollbar-track:hover {
                background: rgba(255, 255, 255, .3);
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
                border-radius: 12px;
            }
        }

        .MuiButtonBase-root {
            padding: 4px 2px 4px 4px;
            max-width: 100%;
            border-radius: 4px;
            margin-bottom: 8px;
            width: unset;
            min-width: 38px;

            * {
                pointer-events: none;
            }

            >svg {
                width: 25px;
                height: 25px;
                min-width: 25px;
                min-height: 25px;

                path {
                    fill: #ffffff;
                }
            }

            .MuiListItemIcon-root {
                min-width: unset;
            }

            .MuiListItemText-root {
                margin: 0 !important;
                white-space: nowrap;
                overflow: hidden;
            }

            .menu_icon {
                width: 30px;
                height: 30px;
                min-width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;

                img {
                    width: 25px;
                    height: 25px;
                    max-height: 100%;
                }
            }

            &.mm-active {
                >svg {

                    path {
                        fill: var(--theme-black);
                    }
                }

                &.initial_active.test_0 {
                    svg {
                        &.down_icon {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        .MuiCollapse-container {
            max-width: calc(100% - 30px);
            margin-left: auto;
            width: 100%;

            .MuiCollapse-container {
                max-width: calc(100% - 10px);
            }
        }

        .MuiListItemText-root {
            >span {
                color: #ffffff;
                font-family: inherit;
                font-size: 18px;
                margin-left: 6px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inherit;
            }
        }

        .sub_menu {
            .MuiListItemText-root {
                >span {
                    color: var(--theme-black);
                }
            }

            >svg {
                path {
                    fill: var(--theme-black);
                }
            }

            // Start: css for different level
            &.sub_menu_3,
            &.sub_menu_4 {
                .MuiListItemText-root {
                    >span {
                        font-size: 16px;
                    }
                }

                &.MuiButtonBase-root {
                    padding: 3px 0px 3px 4px;

                    >svg {
                        width: 22px;
                        height: 22px;
                        min-width: 22px;
                        min-height: 22px;
                    }
                }
            }

            &.sub_menu_3.mm-active {
                &:not(.page_links) {
                    background-color: rgba(255, 255, 255, 0.22);
                }
            }

            // End: css for different level
        }


        .mm-active {
            .MuiListItemText-root {
                >span {
                    color: var(--theme-black);
                }
            }

            svg {

                path,
                * {
                    fill: var(--theme-black) !important;
                }
            }

            .menu_icon {
                img {
                    filter: brightness(20%);
                }
            }

            .menu_name {
                color: var(--theme-black);
            }

            &.main_menu {
                background-color: #ffffff;
            }

            &.sub_menu:not(.page_links) {
                background-color: rgba(255, 255, 255, 0.42);
            }

            &.sub_menu.page_links {
                background-color: rgba(255, 255, 255, 0.1);

                .MuiListItemText-primary {
                    color: #ffffff;
                }
            }
        }
    }

    .toggle_back,
    .sidebar_toggle {
        position: absolute;
        border-radius: 50%;
    }

    .sidebar_toggle {
        height: 70px;
        right: -19px;
        top: 0vh;
        display: none;
        justify-content: center;
        align-items: center;
        background-size: contain;
        background-repeat: no-repeat;

        svg {
            &:first-child {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -1;
                right: -1px;

                * {
                    fill: var(--theme-blue);
                }
            }
        }

        &.rect_btn {
            border-radius: 4px;
            width: 35px;
            height: 60px;
            right: -15px
        }

        svg:last-child {
            transition: all .4s ease-in-out;
            transform: rotate(0);
        }
    }

    &.sidebar-lg {
        width: $sidebar_lg;

        .sidebar_toggle {
            svg:last-child {
                transform: rotate(180deg);
            }
        }

        .side-navigation-panel-select-option-text {
            width: 100%;
            transition: width .4s ease-in-out;
        }

        .MuiButtonBase-root.MuiListItem-root.mm-active.initial_active.test_0 {

            &.main_menu,
            &.sub_menu {
                +.MuiCollapse-container {
                    min-height: 0px;
                    height: auto;
                    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    transition-duration: 283ms;
                    visibility: visible;
                }
            }
        }
    }

    &.sidebar-sm {
        .side-navigation-panel-select-option-text {
            opacity: 0;
            visibility: hidden;
            width: 0;
        }

        .side-navigation-panel-select-option-icon {
            display: none !important;
        }

        .MuiListItemText-root {
            display: none;
        }

        .MuiButtonBase-root {
            width: fit-content !important;

            >svg {
                display: none;
            }
        }

        .MuiCollapse-container {
            position: absolute;
            z-index: -1;
        }
    }

    .logo_large,
    .dash_small_logo {
        display: none;
    }

    .dash_small_logo {
        width: calc($sidebar_w);
        height: calc($sidebar_w);
        max-height: $sidebar_w;
        min-width: calc($sidebar_w);
        display: flex;
        display: flex;
        justify-content: center;
        align-items: center;
        // transition: all 0.4s ease-in-out;

        @media screen and (max-width: ($sm - 1px)) {
            width: $sidebar_w_s;
            height: $sidebar_w_s;
            max-height: $sidebar_w_s;
            min-width: $sidebar_w_s;
        }

        .logo-lg {
            display: flex !important;
            justify-content: center;
            align-items: center;
            max-height: 100%;
            max-width: 100%;

            img {
                width: 90%;
                height: 90% !important;
                max-height: 90%;
                object-fit: cover;
            }
        }
    }

    // add class full_sidebar in vertical-menu for full height sidebar
    &.full_sidebar {
        z-index: 1048;
        top: 0;

        #sidebar-menu {
            // padding: 50px 10px 0 15px !important;
            // padding: 15px 10px 0 15px !important;
            height: calc(100vh - $sidebar_w);

            // @media screen and (max-width: ($sm - 1px)) {
            //     padding: 15px 10px 0 15px !important;
            // }

            @media screen and (max-width: ($sm - 1px)) {
                height: calc(100vh - $sidebar_w_s);
            }
        }

        .sidebar_toggle {
            top: $sidebar_w;

            @media screen and (max-width: ($sm - 1px)) {
                top: $sidebar_w_s;
            }
        }

        .logo_large {
            display: flex;
            width: 90%;

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    &:hover {
        .multi_level_sidebar {

            &::-webkit-scrollbar-corner,
            &::-webkit-scrollbar-track {
                background: rgba(255, 255, 255, .3) !important;
            }

            &::-webkit-scrollbar-thumb {
                background: #f7f0f0 !important;

                &:hover {
                    background: #ffffff !important;
                }
            }
        }
    }
}

body {
    &.is_touch {
        .vertical-menu .sidebar_toggle {
            display: flex;
        }

        .page_title {
            padding-left: 16px;
        }
    }

    .page_title {
        padding-left: 16px;

        .right_master {
            min-width: 320px;
            // max-width: 320px;

            @media screen and (max-width: ($lg - 1px)) {
                min-width: 280px;
                // max-width: 280px;
            }

            @media screen and (max-width: ($md - 1px)) {
                min-width: unset;
            }

            button span.fw-semibold {
                white-space: nowrap;
            }

            >div.d-flex {
                flex-wrap: wrap;
            }
        }
    }
}

@keyframes blink {
    from {
        scale: .6;
    }

    to {
        scale: 1.1;
    }
}

@keyframes leftRight {
    from {
        left: 0px;
    }

    to {
        left: 10px;
    }
}

.table_wrap {
    overflow: hidden;
}

table {

    td,
    th {
        white-space: nowrap;
        vertical-align: middle;
    }

    td {
        .btn.btn-icon {
            width: 30px;
            height: 30px;
            padding: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 12px;
            }
        }
    }
}

form,
.form_layout {
    .form-control {
        font-size: 14px;
        border-radius: $borderRadius;
        padding: 0.375rem .75rem;
        color: var(--dark-text);

        &:read-only {
            background: var(--readonly-color);
        }
    }

    textarea {
        line-height: 1.2;
    }

    select,
    input.form-control {
        height: $hasBtn !important;
    }

    select.form-control {
        padding: 0.375rem 1rem 0.375rem .75rem !important;
    }

    .input-group {
        border-radius: $borderRadius;

        .input-group-text {
            background: transparent !important;
            border-radius: $borderRadius 0 0 $borderRadius;
            height: 100% !important;
            padding: 0;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            color: var(--text-global);
        }

        input.form-control {

            &::-ms-input-placeholder,
            &:-ms-input-placeholder,
            &::placeholder {
                color: #989898;
                opacity: 1;
            }
        }

        &-preappend {
            .input-group-text {
                border-radius: $borderRadius 0 0 $borderRadius;
            }

            input {
                padding: .375rem .75rem;
            }
        }

        &-append {
            .input-group-text {
                border-radius: 0 $borderRadius $borderRadius 0;
            }
        }

        svg,
        img {
            width: 25px;
            height: 25px;

            &.btn-acc-icon.hide_check {
                z-index: 4;
                background: #fff;
            }

            g,
            path {
                fill: var(--gray-e9);
            }
        }

        &.search_box {

            svg,
            img {
                width: 20px;
                height: 20px;
            }

            &.input-group svg path {
                fill: transparent !important;
            }
        }
    }

    .Sport__list .sport_item input {
        height: unset !important;
    }

    .trans_input input {
        color: transparent !important;
        background: transparent !important;
    }
}

.choices_select {
    position: relative;
    display: flex;
    align-items: center;

    .choices {
        width: 100%;
        min-width: 100px;
        margin-bottom: 0 !important;
        position: relative;
        z-index: 2;

        .choices__inner {
            min-height: 36.52px;
            padding: 6px 8px;
            font-size: 1rem;
            border-radius: 4px;
            border-color: var(--bs-border-color);
            background-color: transparent;

            .choices__input {
                padding: 2px 0 0px 2px;
            }
        }

        .choices__item {
            padding-right: 0 !important;

            &::after {
                display: none;
            }
        }

        .choices__button {
            font-size: 14px;
            border-left-color: #ffffff;
            filter: invert(1) grayscale(100%) brightness(200%);
            opacity: .5 !important;

            &:hover {
                opacity: 1 !important;
                color: var(--bs-danger);
            }
        }

        .choices__list {
            &.choices__list--dropdown {
                border-color: var(--bs-border-color) !important;
                box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important;

                .choices__item {
                    padding: 5px 10px !important;
                }

                .choices__input {
                    background: transparent;
                    border-color: var(--bs-border-color);
                    padding: 6px 10px !important;
                }

                .choices__list {
                    max-height: 220px;
                }
            }

            &.choices__list--multiple {
                .choices__item {
                    background-color: var(--bs-primary);
                    border-color: var(--bs-primary);
                    padding-right: 5px !important;
                    white-space: nowrap;
                }
            }

            &.choices__list--single {
                padding: 0;
                font-size: 14px;

                .choices__item.choices__item--selectable {
                    background: transparent !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box;
                    line-clamp: 1;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }

        &[data-type*=select-one] {
            &::after {
                display: none;
            }

            .choices__inner {
                padding: 5px 30px 5px 8px !important;
                display: flex;
                align-items: center;
            }

            .choices__item--selectable.is-selected {
                background-color: rgba(var(--bs-primary-rgb), .15) !important;
                color: var(--bs-primary);
            }

            // &::after {
            //     content: "\f282";
            //     border: none;
            //     font-family: bootstrap-icons !important;
            //     position: absolute;
            //     top: calc(50% - 10px);
            //     right: 10px;
            //     margin-top: 0 !IMPORTANT;
            //     width: 20px;
            //     height: 20px;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     font-size: 14px;
            //     transition: transform .1s ease;
            // }

            // &.is-open::after {
            //     transform: rotateZ(180deg);
            // }
        }
    }

    .select_icon {
        position: absolute;
        right: 10px;
        z-index: 1;
        transform: rotateZ(0deg);
        transition: transform .1s ease;
    }

    .choices.is-open~.select_icon {
        transform: rotateZ(180deg);
    }
}

.dot {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:after {
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 50%;
        position: absolute;
    }

    &.bg-danger {
        &:after {
            background-color: rgba(var(--bs-danger-rgb), .3);
        }
    }

    &.bg-success {
        &:after {
            background-color: rgba(var(--bs-success-rgb), .3);
        }
    }

    &.bg-warning {
        &:after {
            background-color: rgba(var(--bs-warning-rgb), .3);
        }
    }

    &.bg-primary {
        &:after {
            background-color: rgba(var(--bs-primary-rgb), .3);
        }
    }

    &.bg-info {
        &:after {
            background-color: rgba(var(--bs-info-rgb), .3);
        }
    }
}

.datepicker-panel {
    ul {
        li[data-view="day"] {
            color: var(--theme-black);
        }
    }
}

.datepicker {
    &.dropdown-menu {
        padding: 0 !important;

        &.datepicker-orient-right {
            left: unset !important;
            right: 20% !important;
        }
    }

    table {
        border-collapse: separate !important;
        border-spacing: 2px 2px !important;

        tr {

            th,
            td {
                font-size: 14px !important;
            }
        }

        .day,
        .month,
        .year,
        .decade,
        .century {
            color: #000000;

            &.active {
                background-color: #333333 !important;
            }

            &.disabled {
                opacity: .5;
            }

            &.old {
                opacity: .3;
            }
        }
    }
}

.btn-link {
    color: var(--dark-text);
    text-decoration: none !important;

    &:hover {
        color: var(--theme-blue) !important;

        &.danger {
            color: var(--bs-danger) !important;
        }
    }
}

.user {
    position: relative;
}

.profile img {
    height: 80px;
    width: 80px;
    margin-top: 2px;
}

.profile {
    position: absolute;
    top: -50px;
    left: 38%;
    height: 90px;
    width: 90px;
    border: 3px solid #fff;
    border-radius: 50%;
}

.follow {
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    height: 35px;
}

.stats span {
    font-size: 29px;
}

.bg-light {
    background-color: #E3F5FB !important;
}

.bg-light-gray {
    background-color: #f5f8f9 !important;
}

.btn-soft-secondary:active,
.btn-soft-secondary:focus,
.btn-soft-secondary:hover {
    background-color: #acafbf;
}

input::file-selector-button {
    height: 44px !important;
}

// .delete_cross {
//     width: 15px;
//     height: 15px;
//     display: inline-flex;
//     justify-content: center;
//     align-items: center;
//     background: #ffffff;
//     color: var(--bs-danger);

//     &:hover {
//         background: var(--bs-danger);
//         color: #ffffff;
//     }

//     &.small_bordered {
//         width: 20px;
//         height: 20px;
//         min-width: 20px;
//         min-height: 20px;
//         border-radius: 50%;
//         border: 1px solid var(--bs-danger);
//     }

//     &.box_right_top {
//         position: absolute;
//         right: -5px;
//         top: -5px;
//         padding: 1px;
//     }
// }

.pointer_none {
    cursor: initial;
}


.badge_fix .badge {
    line-height: 1.3;
    font-weight: normal;
    letter-spacing: 0.5px;
}

.datepicker {
    z-index: 999 !important;
}

.form-check {
    &.form-control {
        .checkbox {
            opacity: 1;

            &.form-check-input:disabled~.form-check-label,
            &.form-check-input[disabled]~.form-check-label {
                opacity: 1;
            }
        }
    }

    &.form-switch {
        .form-check-input {
            &:disabled {
                opacity: .8 !important;
            }

            &:checked:disabled {
                opacity: .5 !important;
            }
        }
    }
}

.right-0 {
    right: 0px !important;
}

.btn-soft-primary {
    background-color: rgba(var(--bs-primary-rgb), .2);
}

// Starts: Header section

// .sidebar-sm {
//     .navbar-header {
//         @media screen and (max-width: ($sm - 1px)) {
//             margin-left: calc($sidebar_w_s - 20px);
//         }
//     }
// }

.logo_200 {
    width: 200px;
    height: 200px;
    max-width: 200px;
    max-height: 200px;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

.navbar-header {
    height: $sidebar_w;

    @media screen and (max-width: ($sm - 1px)) {
        height: $sidebar_w_s;
    }

    // .navbar-brand-box {
    //     background: var(--theme-blue) !important;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     opacity: 0 !important;
    //     height: $sidebar_w;
    //     width: $sidebar_w;
    //     min-width: $sidebar_w;

    //     @media screen and (max-width: ($sm - 1px)) {
    //         height: $sidebar_w_s;
    //         width: $sidebar_w_s;
    //         min-width: $sidebar_w_s;
    //     }

    //     .logo-lg {
    //         display: block !important;
    //     }

    //     .dash_small_logo {
    //         max-width: 100%;

    //         img {
    //             max-width: 100%;
    //             height: unset;
    //         }
    //     }
    // }

    .dropdown {
        button {
            span {
                font-weight: 500 !important;
                color: var(--bs-header-item-color) !important;
                display: flex;
                align-items: center;

                .text-truncate {
                    max-width: 150px;
                    display: block;

                    @media screen and (max-width: ($xs - 1px)) {
                        max-width: 80px;
                    }
                }
            }
        }
    }

    .header-profile-user {
        width: 36px;
        height: 36px;
        border: 1px solid #e6e6e6;
    }

    .dropdown {
        height: 100%;
        display: flex;
        align-items: center;

        .dropdown-menu {
            right: 0 !important;
            left: auto !important;
            top: 100%;

            @media screen and (max-width: ($sm - 1px)) {
                left: 18% !important;
                right: 10px !important;
                max-width: 80%;
            }
        }
    }

    .logo_large {
        transform: translatex($sidebar_w);
        margin-left: 15px;

        @media screen and (max-width: ($sm - 1px)) {
            transform: translatex($sidebar_w_s);
        }
    }
}

// main page title 
.page_title {
    text-align: left;
    margin-bottom: 9.5px;
    gap: 10px;
    height: $pageTitle;

    h3 {
        font-size: 18px;
        text-transform: capitalize;
        line-height: 1.2;
        max-width: calc(100% - 40px);
        word-break: break-word;
        font-weight: 500;
    }

    .right_menu_btn {
        margin-left: auto;
        padding: 0 !important;
        height: unset;
    }

    .btn {
        height: unset;
        font-size: 13px;
    }
}

h4,
.main_title {
    font-size: 16px !important;
    margin-bottom: 1rem;
    font-weight: 600;

    &.sub_title {
        font-size: 15px !important;
    }
}

.main_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.left_header {
    min-height: 19.19px;

    h4 {
        font-size: 18px !important;
    }
}

.sub_title {
    margin-bottom: 1rem;
}

// main page layout and its responsive (left and right section scroll is managed by here)
.main_layout {
    position: relative;

    &.page_scroll {
        .left_content {
            min-height: calc(100vh - ($sidebar_w + 62.81px + $pageTitle));

            @media screen and (max-width: ($sm - 1px)) {
                min-height: calc(100vh - ($sidebar_w_s + 62.81px + $pageTitle));
            }

            &.full {
                .inside_scroll {
                    max-width: calc(100vw - 118px);

                    @media screen and (min-width: $sm) {
                        max-width: calc(100vw - 160px);
                    }
                }
            }

            &:not(.full) {
                @media screen and (min-width: $md) and (max-width: ($lg - 1px)) {
                    padding-right: calc(280px + 1rem);
                }

                @media screen and (min-width: $lg) {
                    padding-right: calc(320px + 1rem);
                }

                @media screen and (min-width: $xl) {
                    padding-right: calc(320px + 1.5rem);
                }

                .inside_scroll {
                    max-width: calc(100vw - 118px);

                    @media screen and (min-width: $sm) {
                        max-width: calc(100vw - 160px);
                    }

                    @media screen and (min-width: $md) and (max-width: ($lg - 1px)) {
                        max-width: calc(100vw - 450px);
                    }

                    @media screen and (min-width: $lg) {
                        max-width: calc(100vw - 490px);
                    }
                }
            }

            .inside_scroll {
                flex-grow: 1;
            }

            >.card {
                max-height: calc(100vh - ($sidebar_w + 62.81px + $pageTitle));
                overflow-y: auto;

                @media screen and (max-width: ($sm - 1px)) {
                    max-height: calc(100vh - ($sidebar_w_s + 62.81px + $pageTitle));
                }
            }
        }

        .right_content {
            @media screen and (min-width: $md) {
                position: fixed;
                right: 18px;
            }
        }
    }

    .right_content {
        height: calc(100vh - ($sidebar_w + 62.81px + $pageTitle));
        top: calc($sidebar_w + 24px + 46px);

        transition: all .3s ease-in-out;

        @media screen and (max-width: ($sm - 1px)) {
            height: calc(100vh - ($sidebar_w_s + 62.81px + $pageTitle));
        }

        @media screen and (max-width: ($sm - 1px)) {
            top: calc($sidebar_w_s + 24px + 46px);
        }

        .main_buttons {
            padding: 0 1rem;
        }

        .inside_scroll {
            overflow-y: auto;
            overflow-x: hidden;
            flex-grow: 1;
            height: calc(100vh - ($sidebar_w + $initialScroll - 15px));

            @media screen and (max-width: ($sm - 1px)) {
                height: calc(100vh - ($sidebar_w_s + $initialScrollSM - 15px));
            }

            // add respective class of included compoenent in div of class inside_scroll
            &.has_btns {
                margin-bottom: 15px;
                height: calc(100vh - ($sidebar_w + $initialScroll + $hasBtn));

                @media screen and (max-width: ($sm - 1px)) {
                    height: calc(100vh - ($sidebar_w_s + $initialScrollSM + $hasBtn));
                }
            }

            &.has_head {
                height: calc(100vh - ($sidebar_w + $initialScroll + $hasHead));

                @media screen and (max-width: ($sm - 1px)) {
                    height: calc(100vh - ($sidebar_w_s + $initialScrollSM + $hasHead));
                }

                &.has_tab {
                    height: calc(100vh - ($sidebar_w + $initialScroll + $hasHead + $hasTab));

                    @media screen and (max-width: ($sm - 1px)) {
                        height: calc(100vh - ($sidebar_w_s + $initialScrollSM + $hasHead + $hasTab));
                    }
                }

                &.has_btns {
                    height: calc(100vh - ($sidebar_w + $initialScroll + $hasHead + $hasBtn));

                    @media screen and (max-width: ($sm - 1px)) {
                        height: calc(100vh - ($sidebar_w_s + $initialScrollSM + $hasHead + $hasBtn));
                    }

                    &.has_tab {
                        height: calc(100vh - ($sidebar_w + $initialScroll + $hasHead + $hasBtn + $hasTab));

                        @media screen and (max-width: ($sm - 1px)) {
                            height: calc(100vh - ($sidebar_w_s + $initialScrollSM + $hasHead + $hasBtn + $hasTab));
                        }
                    }
                }
            }
        }
    }

    .right_menu {
        min-width: 320px;
        max-width: 320px;

        @media screen and (max-width: ($lg - 1px)) {
            min-width: 280px;
            max-width: 280px;
        }

        @media screen and (max-width: ($md - 1px)) {
            position: fixed;
            min-width: 320px;
            max-width: 320px;
            right: -350px;
            opacity: 0;
            visibility: hidden;
            transform: translateX(350px);
            transition: all .5s ease-in-out;

            &.open_menu {
                transform: translateX(0);
                // display: block;
                right: 18px;
                opacity: 1;
                visibility: visible;
            }

            .card {
                border-left: 1px solid #efefef;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }
        }

        @media screen and (max-width: ($xxs - 1px)) {
            min-width: 290px;
            max-width: 290px;
        }
    }
}

.sticky-header {
    .right_content {
        height: calc(100vh - ($sidebar_w + (62.81px + $pageTitle) - 46px));
        top: calc($sidebar_w + 24px);

        @media screen and (max-width: ($sm - 1px)) {
            height: calc(100vh - ($sidebar_w_s + (62.81px + $pageTitle) - 46px));
        }

        @media screen and (max-width: ($sm - 1px)) {
            top: calc($sidebar_w_s + 24px);
        }
    }
}

// inside nav tab buttons design
.details_header {
    height: 38px;
    background: #ffffff;
    display: flex;
    align-items: center;
    gap: 15px;

    @media screen and (max-width: ($xl - 1px)) {
        gap: 8px;
    }

    &.nav {
        margin-bottom: 20px;

        .nav-item {
            height: 36px;

            .nav-link {
                padding: 5px 0;
                height: 100%;
                line-height: 26px;
                font-size: 14px;
                cursor: pointer;

                &:hover:not(.active) {
                    color: var(--bs-gray-700);

                    &:after {
                        background: var(--bs-gray-500);
                        transform: scale(1);
                    }
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-left: 0;
            }
        }
    }
}

.main_content_tab {
    .nav-tabs .nav-link {
        border: none;
        border-bottom: 1px solid #efefef;
    }
}

.image_icon {
    max-width: 60px;
    max-height: 40px;
    display: flex;

    svg {
        width: 100%;
        height: auto;
    }

    img {
        width: auto;
        max-width: 100%;
        object-fit: contain;

        max-height: 40px;
    }
}

.right_item {
    .image_icon {
        &.ball_shape {
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;

            img {
                width: 100%;
                max-width: 100%;
                object-fit: cover;
                mix-blend-mode: darken;
            }

            svg {
                min-width: 40px;
                min-height: 40px;
                max-width: 40px;
                max-height: 40px;
            }

            &:not(.ball_shadow) img {
                border: 2px solid rgba(73, 72, 71, 0.2);
            }
        }
    }

    &.active .image_icon.ball_shape:not(.ball_shadow) img {
        border-color: rgba(var(--theme-blue-rgb), .75);
    }
}

// css for right sidebar content listing
.right_list {
    .right_item {
        display: flex;
        align-items: center;
        color: var(--dark-text);
        position: relative;
        padding: 8px 14px;
        cursor: pointer !important;

        &.opacity-50 {
            cursor: auto !important;
            color: var(--dark-text) !important;
        }

        >span:first-child {
            display: flex;
        }

        .image_icon:not(.ball_shape) {
            max-width: 60px;
            max-height: 40px;
            display: flex;
            min-width: fit-content;
            justify-content: flex-start;

            svg {
                width: 100%;
                height: auto;
                max-width: 60px;
            }

            img {
                width: auto;
                max-width: 100%;
                object-fit: contain;
                // max-height: 40px;
                max-width: 60px;
            }
        }

        &.active {
            color: var(--theme-blue);
            background: rgba(var(--theme-blue-rgb), .08);

            .small_svg_set {
                svg {
                    &:not(.fill) {
                        fill: none;
                    }

                    &.fill {
                        * {
                            fill: var(--theme-blue);
                        }
                    }

                    .stroke {
                        stroke: var(--theme-blue);
                    }
                }
            }
        }

        &:hover {
            color: var(--theme-blue);
        }

        .right_title {
            display: grid;

            span {
                line-height: 1.4;
            }
        }
    }

    .action_btns {
        display: none;
        gap: 5px;
        right: 10px;

        a:hover svg {
            color: var(--theme-blue) !important;
        }
    }

    .right_item_wrap {
        &:hover {
            .right_item {
                color: var(--theme-blue);
                background: #f9f9f9;
            }

            .action_btns {
                display: flex;

                svg {
                    font-size: 14px;
                }
            }

            &.has_delete,
            &.has_copy {
                .right_item {
                    padding-right: 34px;
                }
            }

            &.has_delete.has_copy {
                .right_item {
                    padding-right: 55px;
                }
            }

            // &.count_3 {
            //     .right_item {
            //         padding-right: 80px !important;
            //     }
            // }
        }

        &.threejs_ball {
            .right_item {
                padding: 5px 16px;

                canvas {
                    width: 40px !important;
                    height: 40px !important;
                    transition: all .3s ease-in-out;
                    mix-blend-mode: darken;
                }

                &.active {
                    canvas {
                        width: 70px !important;
                        height: 70px !important;
                    }
                }
            }
        }
    }
}

.ball_shadow {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 12px;
        bottom: -8px;
        left: 0px;
        background: radial-gradient(farthest-side, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        opacity: .5;
    }
}

.color_circle {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;

    &.dot_lg {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
    }

    &.dot_sm {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
    }

    &.dot_md {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
    }
}

.sq_no_ref {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        margin-top: 1px;

        // font-family: sans-serif;
    }

    &.type_2 {
        width: 40px;
        height: 40px;
        border-radius: 0;
        background-image: url("../img/icons/hexagon.png");
        background-size: contain;
        // clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%); // pentagon
        // clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); // hexagon

        span {
            color: #000000;
            font-size: 16px;
            // margin-top: 6px;

        }
    }
}

// custom form switch
.form-switch {
    .form-check-input {
        border: none !important;
        background-color: #D6D9DD;
        background-image: url('../img/check_dot.svg') !important;

        &:checked {
            background-color: var(--theme-blue);
        }
    }
}

// remove arror of input type number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// table layout in content section
.table {
    tr {
        &:hover {
            td {
                background: var(--readonly-color);
            }

            &.bg_trans {
                td {
                    background: transparent !important;
                }
            }
        }

        th {
            text-align: left;
            font-size: 0.8125rem;
            border: none;
        }

        td {
            text-align: left;
            font-size: 0.8125rem;
            border: none;
        }
    }

    &.no_td_bg {
        tr {
            td {
                background: transparent !important;
            }
        }
    }

    &.no_border {
        tr {
            td {
                border: none !important;
            }
        }
    }

    &.quote_list {
        tr {

            td,
            th {
                min-width: 120px;
            }
        }
    }

    &.discount_table {
        tr {
            th:last-child {
                width: 140px;
                text-align: right;
            }

            th {
                padding: 6px 8px;
            }

            td {
                padding: 6px 8px;

                &:first-child {
                    min-width: 200px;
                }

                input {
                    text-align: right;
                    padding: 4.25px;
                    border: none !important;
                    min-width: 60px;

                    &::placeholder,
                    &:-ms-input-placeholder,
                    &::-ms-input-placeholder {
                        /* Firefox, Chrome, Opera */
                        text-align: right;
                    }

                    &:read-only {
                        padding: 10px;
                    }
                }
            }
        }

        // tbody tr {
        //     &:hover {
        //         background: #f5f5f5;
        //     }
        // }
    }
}

// form  and cpmponent styling
.form-group {
    &:not(.mb-0) {
        margin-bottom: 1.5rem !important;
    }
}

// Start: css for MUI single and multi select
.MuiOutlinedInput-root {
    border-radius: $borderRadius !important;
}

.MuiInputBase-root {
    .MuiSelect-select {
        padding: 0.37rem 1.75rem 0.37rem 0.75rem;
        min-height: $hasBtn;
        box-sizing: border-box;
        line-height: 1.5;
        font-size: 15px;
        color: var(--dark-text);
        background: transparent !important;
        white-space: initial;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 24px;

        ~fieldset {
            height: $hasBtn;
            top: 0;
        }

        ~.MuiSelect-icon {
            top: calc(50% - 10px);
        }

        .select_with_img .img_wrap {
            display: none;
        }
    }

    .PrivateNotchedOutline-root-1 {
        top: 0;
    }

    .MuiSvgIcon-root {
        color: var(--theme-black);
        width: 20px;
        height: 20px;
    }

}

.MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: var(--bs-input-border-color) !important;
}

.MuiSelect-root[aria-expanded="true"] {
    ~.MuiSvgIcon-root {
        transform: rotate(180deg);
        color: var(--theme-blue);
    }

    ~.MuiOutlinedInput-notchedOutline {
        border-color: var(--theme-blue) !important;
        border-width: 1px !important;
    }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    max-width: calc(100vw - 150px);
    border: 1px solid var(--bs-border-color);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.10);
    border-radius: $borderRadius;
    width: fit-content !important;

    .MuiMenu-list>li {
        font-size: 14px;

        .MuiTypography-body1 {
            font-size: 14px;
        }

        .select_with_img {
            .img_wrap {
                display: flex;
                justify-content: center;

                &.size_lg {
                    width: 60px;
                    max-width: 60px;
                    max-height: 60px;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

.modal_select_show {
    display: none;
}

// select dropdown with image
.modal_select {
    max-width: 100%;

    span {
        line-height: unset !important;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        max-width: calc(100% - 15px);
    }

    .modal_select_hide {
        display: none;
    }

    .modal_select_show {
        display: flex;
    }

    .small_svg_set~.d-flex.flex-column {
        width: 100%;
    }

    .MuiInputBase-root {
        max-width: 100%;
    }

    .dropdown_with_icon {
        max-width: 220px;
        min-width: 100%;
    }
}

.small_svg_set {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        &:not(.fill) {
            fill: none;
        }

        .stroke {
            stroke: rgb(73, 72, 71);
        }
    }

    max-width: 60px;
    max-height: 40px;

    svg {
        width: 100%;
        height: auto;
    }

    img {
        width: auto;
        max-width: 100%;
        object-fit: contain;

        max-height: 40px;
    }
}

.MuiListItem-root {
    &.Mui-selected {
        background-color: #f6f6f6 !important;

        .small_svg_set {
            svg {

                &:not(.fill) {
                    fill: none;
                }

                .stroke {
                    stroke: var(--theme-blue);
                }
            }
        }
    }
}

.MuiListItem-root .dropdown_with_icon {
    max-width: "300px";

    span:last-child {
        white-space: normal;
        line-height: 1.2 !important;
    }
}

.MuiCheckbox-root {
    padding: 0 !important;
    margin-right: 10px !important;
    color: var(--bs-input-border-color) !important;

    &.Mui-checked {
        color: var(--theme-blue) !important;
    }
}

.MuiList-root.MuiMenu-list {
    padding: 0;

    >li {
        padding: 6px 8px !important;
    }
}

// End: css for MUI single and multi select

// page setup logo
.page_setup_logo {
    max-width: 200px;
    max-height: 100px;
    display: flex;

    &.square {
        max-width: 100px;
        max-height: 100px;
    }

    img {
        width: 100%;
        object-fit: contain;
        object-position: left;
    }
}

.mini_text {
    width: 70px;
}

.min-w-120 {
    min-width: 120px;
}

.min-w-150 {
    min-width: 150px;
}

.min-w-180 {
    min-width: 180px;
}

.max-w-150 {
    max-width: 150px;
}

.max-w-180 {
    max-width: 180px;
}

.max-w-200 {
    max-width: 200px;
}

.max-w-388 {
    max-width: 388px;
}

.max-w-400 {
    max-width: 400px;
}

.file_choose {
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        width: 0;
    }
}

.head_item {
    &.wrap_address {
        max-width: 320px;
        white-space: break-spaces;
    }
}

// MUI radio buttons

.MuiRadio-colorSecondary {
    // color: var(--theme-blue) !important;

    &.Mui-checked {
        color: var(--theme-blue) !important;
    }
}

.MuiFormControl-root {

    .MuiIconButton-root {
        margin-right: 5px !important;
    }

    .MuiFormControlLabel-root {
        margin-left: 0 !important;

        .MuiRadio-root {
            padding: 0 !important;

        }
    }

    .MuiFormGroup-root {
        .MuiFormControlLabel-root {
            margin-right: 0 !important;
        }
    }

    .MuiSvgIcon-root {
        width: 20px !important;
        height: 20px !important;
    }

    .MuiTypography-body1 {
        font-size: var(--bs-body-font-size);
    }
}

// custom radio box design with icon
// .page_type_radio {
//     position: relative;
//     margin-bottom: 0 !important;

//     .MuiTypography-root>div {
//         display: flex !important;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center !important;

//         position: relative;
//         width: 100px;
//         height: 100px;
//         flex-shrink: 0;
//         border-radius: 4px;
//         background: #E6E6E6;
//         border: 1px solid transparent;
//         padding: 5px;

//         svg {
//             width: 45px;
//             height: 30px;

//             path {
//                 stroke: var(--bs-body-color);
//             }
//         }

//         &.landscape {
//             svg {
//                 transform: rotate(90deg) scaleX(-1);
//             }
//         }

//         span {
//             margin-top: 4px;

//             &:first-child {
//                 margin-top: 10px;
//                 margin-bottom: 2px;
//             }

//             &:last-child {
//                 word-break: break-word;
//                 text-align: center;
//                 line-height: 1.2;
//                 flex-grow: 1;

//                 // radio type -> text in 2 lines
//                 max-width: 85px;
//                 white-space: normal;
//                 word-break: normal;
//                 word-wrap: normal;
//                 text-align: center !important;
//             }
//         }
//     }

//     .Mui-checked~.MuiTypography-root>div {
//         background: transparent;
//         border-color: var(--theme-blue);

//         span {
//             color: var(--theme-blue) !important;

//             svg {
//                 path {
//                     stroke: var(--theme-blue);
//                 }
//             }
//         }
//     }

//     .MuiIconButton-root {
//         position: absolute !important;
//         top: 4px;
//         right: 2px;
//     }
// }

.page_type_radio_2 {
    position: relative;
    margin-bottom: 0 !important;
    // width: 100%;
    // width: calc(25% - .5rem);
    // flex-grow: 1;

    width: fit-content;
    padding: 0 3px;

    &.max_w_100 {
        max-width: 100px;
    }

    .MuiRadio-root {
        display: none !important;
    }

    .MuiTypography-root {
        width: 100%;
        height: 100%;

        >div {
            display: flex !important;
            flex-direction: column;
            align-items: center !important;
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 0;
            border: none;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;

            svg {
                width: 28px;
                height: 28px;
                margin-bottom: 5px;

                &:not(.no_svg) {

                    path,
                    rect {
                        stroke: var(--bs-body-color);
                    }
                }

            }

            &.landscape {
                svg {
                    transform: rotate(90deg) scaleX(-1);
                }
            }

            span {
                &:last-child {
                    word-break: break-word;
                    line-height: 1.2;
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100%;
                    font-size: 13px;
                    color: var(--theme-black);

                    // margin-left: 5px;
                    // max-width: calc(100% - 28px);
                    // white-space: nowrap;
                }
            }
        }
    }

    .Mui-checked~.MuiTypography-root>div {
        background: transparent;
        border-color: var(--theme-blue);

        span {
            color: var(--theme-blue) !important;

            svg:not(.no_svg) {

                path,
                rect {
                    stroke: var(--theme-blue);
                }
            }
        }
    }

    .MuiIconButton-root {
        position: absolute !important;
        top: 4px;
        right: 2px;
    }
}

// inputs with increment and decrement buttons
.input_with_buttons {
    position: relative;

    input {
        padding-right: 30px;
    }

    .up_down_btns {
        display: flex;
        flex-direction: column;
        width: 20px;
        position: absolute;
        right: 4px;

        span {
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.disabled {
                cursor: auto;
            }

            svg {
                transform: scale(1.1);
                color: var(--theme-black);
            }

            &:hover {
                svg {
                    color: var(--dark-text);
                }
            }
        }
    }
}

// error pages design
.error_pages {
    min-height: calc(100vh - 94px - 18px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4%;
    background: #ffffff;

    &.full_cover {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #ffffff;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
    }

    .banner_img {
        display: flex;
        justify-content: center;
        max-height: calc(50vh - 215px);
        min-height: 200px;

        &.maintenance_img {
            min-height: 350px;

            &.processing_img {
                min-height: 80px;
                margin: auto;
                max-width: 400px;

                img {
                    object-fit: cover !important;
                }
            }
        }
    }

    .banner_img img {
        width: 100%;
        max-width: 100%;
        object-fit: contain;
    }

    .logo_large {
        width: 100%;
        max-width: 200px;
        height: unset;
        max-height: unset;
    }

    .bx-spin {
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    h1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(3.5rem + 3vw);
    }

    h4 {
        font-size: 24px !important;
        font-weight: 500 !important;

        &.display-4 {
            font-size: calc(1.5rem + 2vw);
        }

        ~p {
            font-size: 16px;
        }
    }
}

div[data-testid="oval-loading"] {
    svg {
        stroke: var(--theme-blue);

        circle {
            stroke: var(--theme-blue);
        }
    }
}

// loader design
.loader_main {
    &.full_loader {
        position: fixed;
        z-index: 9999;
        width: 100vw;
        height: 100vh;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, .15);
        cursor: progress;
    }

    &.save_images {
        background: #ffffff;
        z-index: 997;
    }
}

.Toastify__toast-container--top-right {
    z-index: 1045 !important;
    top: calc($sidebar_w + 10px) !important;

    @media screen and (max-width: ($sm - 1px)) {
        top: calc($sidebar_w_s + 10px) !important;
    }
}

.inside_content {
    background: transparent !important;
}

.form-check {
    &:not(.form-switch) {
        margin-bottom: 8px;
        padding-left: 1.5rem;

        .form-check-input {
            background-color: transparent !important;
            border-width: 1px !important;
            border-color: var(--text-muted);
            width: 18px;
            height: 18px;
            margin-left: -1.5rem;
            margin-top: 1px;

            &:checked[type=checkbox] {
                background-color: var(--theme-blue) !important;
                border-color: var(--theme-blue) !important;
            }

            &.size_sm {
                width: 16px;
                height: 16px;
            }
        }

        &.type_2 {
            .form-check-input {
                &:checked[type=checkbox] {
                    background-color: transparent !important;

                    &:before {
                        // content: "✓";
                        content: "\2713";
                        color: var(--theme-blue) !important;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        label {
            font-weight: 400;
        }
    }
}

.three_element {
    canvas {
        max-width: calc(100vw - 155px);
        max-height: calc(100vh - 230px);
    }
}

.remove_item_wrap {
    >div {
        margin-bottom: 1rem;
    }
}

.remove_item {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;

    span {
        font-size: 13px;

        svg {
            width: 20px;
            height: 20px;
            color: var(--theme-blue);
        }

        &:first-child {
            cursor: pointer;
        }

        &:last-child {
            strong {
                font-weight: 500 !important;
                margin-right: 6px;
            }
        }
    }

    >a {
        padding: 0;
        margin-right: 8px;
        border: 1px solid var(--theme-blue);
        border-radius: 2px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    >a.disabled {
        border-color: var(--text-muted);
    }
}

input,
select {
    &:disabled {
        cursor: auto !important;
        pointer-events: none;
    }
}

.Mui-disabled {
    background: var(--readonly-color);
    cursor: auto !important;
    pointer-events: none;
}

.not_allowed {
    pointer-events: none;

    .form-control,
    select {
        cursor: auto !important;
        pointer-events: none;
        background: var(--readonly-color);
    }

    .form-check-input {
        opacity: 0.8 !important;
        cursor: auto !important;
        pointer-events: none;
    }

    .custom_dropzone,
    label[for="uploadFile"] {
        display: none;
    }

    // .d-flex.gap-2.gap-xl-3.main_buttons {
    //     display: none !important;
    // }

    .MuiSelect-root {
        background: var(--readonly-color) !important;
        cursor: auto !important;
        pointer-events: none;
    }
}

.inside_scroll {

    .rangeslider-horizontal {
        height: 6px;
        border-radius: 10px;
    }

    .rangeslider-horizontal {
        &.w-75 {
            width: 80% !important;
            margin: 0;
        }

        .rangeslider__fill {
            height: 100%;
            background-color: var(--theme-blue);
            border-radius: 10px;
            top: 0;
            box-shadow: none;
        }

        .rangeslider__handle {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);

            &:after {
                content: ' ';
                position: absolute;
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                top: -4px;
                left: -2px;
                border-radius: 50%;
                background-color: #fff;
                box-shadow: unset;
                border: 2px solid #ADADAD;
            }
        }
    }

    .rangeslider {
        box-shadow: unset;
    }
}

.pointer_none {
    pointer-events: none;
}

// Start: Custom tooltip design
.MuiTooltip-tooltipArrow {
    background: #ffffff !important;
    color: var(--dark-text) !important;
    font-size: 11px !important;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
}

.MuiTooltip-arrow {
    position: relative;

    &::before {
        color: #ffffff !important;
        z-index: 2;
        font-size: 6px;
        right: 0px !important;
    }

    &::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        z-index: -1;
    }
}

.MuiTooltip-tooltipPlacementLeft,
.MuiTooltip-tooltipPlacementRight {
    .MuiTooltip-arrow {
        &::after {
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-right: 7px solid rgba(50, 50, 105, 0.15);
            top: -1px;
            right: .4px !important;
        }
    }
}

.MuiTooltip-tooltipPlacementLeft {
    .MuiTooltip-arrow {
        &::after {
            border-right: none;
            border-left: 7px solid rgba(50, 50, 105, 0.15);
            right: unset;
            left: .4px !important;
        }
    }
}

.MuiTooltip-tooltipPlacementBottom,
.MuiTooltip-tooltipPlacementTop {
    .MuiTooltip-arrow {
        &::after {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid rgba(50, 50, 105, 0.15);
            top: .8px;
            left: -1px !important;
        }
    }
}

.MuiTooltip-tooltipPlacementBottom {
    .MuiTooltip-arrow {
        &::after {
            border-top: none;
            border-bottom: 7px solid rgba(50, 50, 105, 0.15);
            top: unset;
            bottom: .8px;
        }
    }
}

// for sidebar tooltip position
.MuiTooltip-tooltipPlacementRight {
    right: -10px;
}

// End: Custom tooltip design

.dual_box_design {
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: $lg) {
        flex-direction: row;
    }
}

.dual_box_wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: calc(100vw - 116px);

    @media screen and (min-width: $lg) {
        width: calc(50% - 0.5rem);
        max-width: 400px;
    }

    .dual_box {
        border-radius: 5px;
        border: 1px solid #CCC;
        background: #FFF;
        flex: 1;
        display: flex;
        flex-direction: column;

        .dual_box_list {
            flex-grow: 1;
            min-height: 254px;
            max-height: 254px;
            overflow-y: auto;
        }

        &.has_btns {
            .dual_box_list {
                min-height: 201.47px;
                max-height: 201.47px;
            }
        }

        .list_item {
            display: flex;
            align-items: center;
            color: var(--dark-text);
            position: relative;
            padding: 6px 40px 6px 16px;

            >canvas {
                width: 40px !important;
                height: 40px !important;
            }

            .ball_shape {
                min-width: 40px;
                min-height: 40px;
                max-width: 40px;
                max-height: 40px;

                img {
                    object-fit: cover;
                    max-height: unset;
                    max-width: 100%;
                    mix-blend-mode: darken;
                }

                svg {
                    min-width: 40px;
                    min-height: 40px;
                    max-width: 40px;
                    max-height: 40px;
                }

                &:not(.ball_shadow) img {
                    border: 2px solid rgba(73, 72, 71, 0.2);
                }
            }

            &.active {
                color: var(--theme-blue);
                background: rgba(var(--theme-blue-rgb), .08);

                .ball_shape:not(.ball_shadow) img {
                    border-color: rgba(var(--theme-blue-rgb), .75);
                }
            }
        }
    }
}

// color picker design
.palette-color-picker {
    .photoshop-picker {
        @media screen and (max-width: ($sm - 1px)) {
            max-width: 320px;
        }

        @media screen and (min-width: $lg) {
            max-width: unset;
        }

        >.flexbox-fix {
            padding: 0 !important;
            gap: 10px;

            @media screen and (max-width: ($sm - 1px)) {
                flex-wrap: wrap;
                gap: 10px;
            }

            >div:nth-child(1) {
                max-width: 100%;
                border: none !important;
                width: 200px;
                height: 200px;
                max-width: 200px;
                max-height: 200px;
                overflow: unset !important;

                @media screen and (min-width: $lg) {
                    width: 250px;
                    height: 250px;
                    max-width: 250px;
                    max-height: 250px;
                }
            }

            >div:nth-child(2) {
                width: 12px !important;
                height: 200px !important;
                border: none !important;
                border-radius: 12px !important;
                overflow: hidden;
                margin-left: 0 !important;

                @media screen and (max-width: ($sm - 1px)) {
                    position: absolute !important;
                    left: 225px;
                }

                @media screen and (min-width: $lg) {
                    height: 250px !important;
                }
            }

            >div:nth-child(3) {
                max-width: 95px !important;

                @media screen and (max-width: ($sm - 1px)) {
                    margin-left: 20px !important;
                    height: 125px;
                }

                .flexbox-fix {
                    height: 100%;
                    display: flex;
                    align-items: flex-end;

                    >div:nth-child(1) {
                        display: none;
                    }

                    >div:nth-child(2) {
                        margin-left: 0 !important;

                        >div {

                            &:nth-child(1),
                            &:nth-child(2) {
                                display: none;
                            }

                            &:nth-child(3) {
                                >div {

                                    &:nth-child(1),
                                    &:nth-child(2),
                                    &:nth-child(3),
                                    &:last-child {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // .sketch-picker {
    //     width: 100% !important;
    //     max-width: 320px;

    //     > div:nth-child(4) {
    //         display: none;
    //     }
    // }
}

.custom_dropzone {
    height: 100px;
    border-radius: 5px;
    border: 2px dashed var(--bs-input-border-color);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    p {
        margin-bottom: 0 !important;
        color: #D2D2D2;
        font-size: 16px;
        text-align: center;
    }
}

.dimention_image {
    max-width: 200px;
    max-height: 100px;
    display: flex;
    margin-bottom: 1rem;

    svg {
        width: 100%;
        height: auto;
    }

    img {
        width: auto;
        max-width: 100%;
        max-height: 100px;
        object-fit: contain;
    }
}

.input_with_img {
    margin-bottom: 1.5rem;

    .form_img {
        max-width: 42px;
        max-height: 42px;
        display: flex;

        svg {
            max-width: 100%;
            object-fit: contain;
            width: auto;
            height: auto;
        }
    }

    .input_with_img_item {
        display: flex;
        align-items: flex-end;
        gap: .5rem;
        flex-wrap: nowrap;
    }

    .form-control {
        margin-bottom: 0 !important;
        max-width: 80px;
        min-width: 80px;
        padding-right: 20px;
        padding-left: 8px;
    }
}

.modal {
    .right_menu_items {
        max-width: 420px;

        .inside_scroll {
            max-height: calc(100vh - 300px);
            overflow-y: auto;
        }
    }
}

.designer_wrap {
    width: 100%;
    overflow: hidden;

    canvas {
        max-width: calc(100vw - 155px);
        max-height: calc(100vh - 230px);
    }

    &.has_header {
        canvas {
            max-width: calc(100vw - 155px);
            max-height: calc(100vh - 300px);
        }
    }

    .designer-right-menu {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(10px) saturate(2);
        width: 300px;
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
        transition: all .5s ease-in-out;

        @media screen and (max-width: ($lg - 1px)) {
            width: 0;
            transform: translateX(350px);
            opacity: 0;
            visibility: hidden;
        }

        .right_list .right_item_wrap {
            .right_item {
                padding: 7px 2px;

                .image_icon {
                    // max-width: 60px;
                    max-height: 35px;

                    img,
                    svg {
                        // max-width: 60px;
                        max-height: 35px;
                    }
                }
            }

            &:hover .right_item {
                background: transparent;
            }
        }

        &.main_design>div:first-child {
            max-height: calc(100vh - 300px);
            overflow-y: auto;
            margin-right: -10px;
            padding-right: 6px;

            .tab-content {
                max-height: calc(100vh - 320px);
                overflow-y: auto;

                margin-right: -6px;
                padding-right: 6px;
            }

            &.accordion {
                max-height: calc(100vh - 200px);

                .sill_list_scroll {
                    max-height: calc(100vh - 435px);
                    overflow-y: auto;
                    margin-bottom: 15px;
                    margin-right: -6px;
                    padding-right: 6px;
                }

                .panel_object_list {
                    max-height: calc(100vh - 500px);
                    overflow-y: auto;
                }

                .tab-content {
                    // max-height: calc(100vh - 372px);
                    margin-right: -6px;
                    padding-right: 6px;

                    .panel_object_list {
                        max-height: calc(100vh - 490px);
                        overflow-y: auto;
                    }
                }
            }

            +.accordion {
                .details_header+.tab-content {
                    max-height: calc(100vh - 540px);
                    overflow-y: auto;
                }
            }
        }

        &.panel_design>div:first-child {
            overflow-y: hidden;

            .accordion-body {
                padding-right: 10px;

                .inside_scroll {
                    max-height: calc(100vh - 360px);
                    overflow-y: auto;
                    margin-right: -10px;
                    padding-right: 6px;
                }
            }
        }

        .scroll_fix_designer {
            &.inside_scroll {
                max-height: calc(100vh - 360px);
                overflow-y: auto;
                margin-right: -10px;
                padding-right: 6px;
            }
        }

        &.open_menu {
            width: 300px;
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
        }

        .accordion {
            --bs-accordion-border-color: transparent;

            .accordion-item {
                .accordion-button {
                    padding: 5px 0;
                    font-size: 17px;
                    min-height: 40px;

                    &::after {
                        margin-left: 10px !important;
                    }

                    &:not(.collapsed) {
                        color: var(--theme-blue);
                        background-color: transparent;

                        &::after {
                            background-image: var(--bs-accordion-btn-icon);
                        }
                    }
                }

                &.hardware_acc {
                    .accordion-header {
                        overflow: hidden;
                        max-width: 100%;
                    }

                    // .accordion-collapse {
                    //     min-height: 100px;
                    // }
                }

                &.hardware_acc .accordion-button.collapsed .ms-auto.dropdown {
                    display: none;
                }
            }

            .accordion-body {
                // padding: 5px 10px;
                padding: 5px 0;

                .panel_object_list {
                    .right_item {
                        padding: 5px 0;
                        background: transparent !important;
                        cursor: pointer;

                        // span.me-2 {
                        //     img {
                        //         width: 40px;
                        //         height: 40px; 
                        //         min-width: 40px;
                        //         min-height: 40px;
                        //     }
                        // }

                        >span:first-child {
                            display: flex;
                        }

                        .right_title {
                            span {
                                &:first-child {
                                    font-weight: 500;
                                    font-size: 14px;
                                }

                                &:last-child {
                                    font-size: 13px !important;
                                }
                            }
                        }

                        &:hover {
                            .right_title {
                                span {
                                    &:first-child {
                                        color: var(--theme-blue) !important;
                                    }
                                }
                            }
                        }

                        // .ball_shape {
                        //     min-width: 40px;
                        //     min-height: 40px;
                        //     max-width: 40px;
                        //     max-height: 40px;

                        //     img {
                        //         width: 100%;
                        //         max-width: 100%;
                        //         object-fit: cover;
                        //         mix-blend-mode: darken;
                        //     }

                        //     svg {
                        //         min-width: 40px;
                        //         min-height: 40px;
                        //         max-width: 40px;
                        //         max-height: 40px;
                        //     }
                        // }

                        .threejs_ball canvas {
                            width: 40px !important;
                            height: 40px !important;
                            mix-blend-mode: darken;
                        }
                    }

                    // &.color_menu {
                    //     max-height: calc(100vh - 400px);
                    //     overflow-y: auto;
                    // }
                }
            }

            .details_header {
                background: transparent !important;

                .nav-item .nav-link.active {
                    background: transparent !important;
                }
            }
        }

        // .add_add_profiles {
        //     min-height: 300px;
        // }

        .details_header,
        .form-control {
            background: transparent !important;
        }

        .nav .nav-item .nav-link {
            background-color: transparent !important;
        }

        // page_type_radio_2 design
        .tab-content .tab-pane .MuiFormControl-root {
            width: 100%;
        }
    }

    &.new_design {
        position: relative;
        overflow: visible;
        padding-left: 16px;

        .designer_header {
            position: absolute;
            background: transparent;
            top: 0;
            // width: calc(100% - 16px);
            margin-left: 16px;
            left: 0;
            z-index: 1;
        }

        .designer_btn {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9;
        }

        .canvas_wrap #container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            // max-width: calc(100vw - 300px);
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;

            canvas {
                max-width: unset;
                max-height: unset;
            }
        }

        .designer-right-menu {
            position: fixed;
            top: 140px;
            right: 18px;
        }

        .design_footer {
            position: absolute;
            z-index: 10;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
        }
    }
}

.label_radio {
    margin: 0 !important;

    .MuiIconButton-root {
        display: none;
    }

    .MuiTypography-root>div {
        border: 1px solid var(--theme-blue);
        border-radius: 4px;
        margin: 0;
        padding: 5px 15px;

        span {
            font-size: 14px;
        }
    }

    .Mui-checked~.MuiTypography-root>div {
        background: var(--theme-blue);
        border-color: var(--theme-blue);

        span {
            color: #ffffff !important;
        }
    }

    &.active {
        background-color: var(--theme-blue);
        color: #ffffff;
    }

    &.frame_type {
        width: 100%;
    }
}

.nested_checkbox {
    .react-checkbox-tree {

        ol,
        li {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        ol ol {
            margin-left: 20px;
        }
    }

    .rct-node {
        .rct-text {
            margin: 8px 0;
            position: relative;
            padding-right: 40px;
            display: flex;
            align-items: center;

            input[type="checkbox"] {
                background-color: transparent !important;
                border-width: 1px !important;
                border-color: var(--text-muted);
                width: 16px;
                height: 16px;
                margin-left: -1.5rem;
                margin-top: 1px;

                &:checked[type=checkbox] {
                    background-color: var(--theme-blue) !important;
                    border-color: var(--theme-blue) !important;
                }
            }
        }

        label {
            margin-bottom: 0;
            display: flex;
            align-items: center;

            input[type="checkbox"] {
                margin-right: 10px;
                width: 14px;
                height: 14px;
            }
        }
    }

    .rct-node-parent {
        button.rct-collapse {
            position: absolute;
            right: 0;
            border: none;
            background: transparent;
            color: var(--bs-body-color);
        }

        &.rct-node-expanded {

            >.rct-text> {
                button.rct-collapse {
                    color: var(--theme-blue);
                }

                label {
                    color: var(--theme-blue);
                }
            }
        }
    }
}

.tab-page {
    .table-responsive {
        max-width: 100%;
    }
}

.seperator {
    border-top: 1px solid var(--bs-border-color);
}

.fixed_m_scroll {
    height: calc(100vh - 165px);
    overflow-y: auto;

    &:not(.add_frame_2) {
        padding-right: 16px;
    }

    &.no_head {
        height: calc(100vh - 145px);
    }

    &.full_h {
        height: calc(100vh - 120px);
    }

    &.add_frame_2 {
        height: calc(100vh - 135px);
        overflow-y: auto;
        margin-bottom: 20px;

        .panel_item {
            font-size: 12px;

            .img_wrap {
                min-width: unset;
                width: auto;
                height: 40px;
                justify-content: start;
            }
        }
    }
}

.row {
    &.custom_row {
        margin-left: -5px;
        margin-right: -5px;

        >div {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &.custom_row_8 {
        margin-left: -8px;
        margin-right: -8px;

        >div {
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    &.custom_row_pdf {
        margin-left: -3px;
        margin-right: -3px;

        >div {
            padding-left: 3px;
            padding-right: 3px;
        }
    }
}

.search_suggestion {
    position: relative;
    width: 100%;

    .form-control {
        padding-right: 25px !important;
    }

    .toggle_icon {
        cursor: pointer;
        position: absolute;
        right: 10px;
        transform: rotateZ(0deg);
        transition: transform .1s ease-in-out;

        &.active {
            transform: rotateZ(180deg);
        }
    }

    .form-control.input_select {
        cursor: pointer;
        background: transparent !important;
        height: 36.52px;

        &.disabled {
            background: var(--readonly-color) !important;
        }
    }

    .suggestions_wrap {
        position: absolute;
        z-index: 999;
        width: 100%;
        background-color: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 8px;
        border: 1px solid var(--bs-border-color);

        // .search_box {
        //     border-radius: 0;
        //     border-left: 0;
        //     border-right: 0;
        //     border-top: 0;
        //     width: 98%;
        //     margin: auto;
        //     padding: 6px 0;
        // }
    }

    .suggestions {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 150px;
        overflow-y: auto;
        width: 100%;

        li {
            // padding: 5px 2px;
            cursor: pointer !important;
            display: flex;
            align-items: center;
            color: #000000;

            &.active {
                color: var(--theme-blue);
            }

            &:hover {
                color: var(--theme-blue);
            }

            &.select-opt {
                .select-item {
                    padding: 5px 10px !important;
                    min-height: 32px;

                    // >div {
                    //     display: flex;
                    //     flex-direction: column;
                    //     justify-content: center;
                    // }

                    &.form-label {
                        cursor: pointer;

                        .form-check-input {
                            margin-left: 0;
                        }
                    }

                    h6 {
                        line-height: 1.5;
                    }
                }

                &:hover,
                &.active {
                    // background-color: rgba(var(--bs-primary-rgb), .1);

                    h6 {
                        color: var(--bs-primary);
                    }
                }
            }

            // label {
            //   display: flex;
            //   align-items: center;
            //   cursor: pointer;

            //   input[type='checkbox'] {
            //     margin-right: 5px;
            //     padding: 2px;
            //   }
            // }
        }
    }

    .no-suggestions {
        padding: 8px;
        color: #999;
    }

    .selected-items {
        margin-top: 10px;

        ul {
            list-style: none;
            padding: 0;


            li {
                padding: 4px;
                background-color: #f4f4f4;
                //   margin-bottom: 4px;
            }
        }
    }
}

.custom-multiselect {
    .multiselect-container {
        color: black;
        height: auto;
        background-color: #f9f9f9;
    }

    .optionListContainer {
        li {
            cursor: pointer;
            color: black;

            &:hover {
                background-color: var(--bs-blue-hover);
                color: white;
            }
        }
    }

    .chip {
        background-color: var(--theme-blue);
        color: white;
    }

    .highlightOption {
        background-color: var(--bs-blue-hover);
        color: white !important;
    }

    .search-wrapper {
        padding: 0 !important;

        .searchBox {
            width: 100%;
            padding: 5px 8px;
            border: none;
        }
    }

    .dropdown {
        max-height: 200px;
        overflow-y: auto;
    }

    .optionContainer {
        >li.option {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 6px 8px;

            input {
                margin: 0 !important;
                width: 16px;
                height: 16px;
                border-radius: 4px;
            }

            &.selected,
            &:hover {
                background-color: transparent;
                color: var(--theme-blue) !important;
            }

            &.selected {
                input[type="checkbox"] {
                    background-color: var(--theme-blue) !important;
                    border-color: var(--theme-blue) !important;
                }
            }
        }

        .notFound {
            color: var(--dark-text);
        }
    }
}



.item_box_wrap {
    .item_box {
        border-radius: 8px;

        .item_box_inner {
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #f4f4f4;
            height: 100%;

            &:hover,
            &.selected_item {
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                background: rgba(var(--theme-blue-rgb), .1);
            }

            @media screen and (max-width: $xs) {
                h5 {
                    font-size: calc(.75rem + .4vw);
                }

                p {
                    font-size: calc(.5rem + .4vw);
                }
            }

            .item_header {
                display: none;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                padding: 5px 6px;

                &.has_checked_items {
                    display: flex;
                }

                span {
                    a {
                        color: var(--text-muted);

                        &:hover {
                            &:first-child {
                                color: var(--bs-primary);
                            }

                            &:last-child {
                                color: var(--bs-danger);
                            }
                        }
                    }
                }
            }

            &:hover {
                .item_header {
                    display: flex;
                }
            }

            .item_box_img {
                background: #f4f4f4;
                display: flex;
                justify-content: center;
                align-items: center;

                max-width: 100%;
                padding: 40px 20px 20px;
                flex-grow: 1;
                max-height: 200px;

                @media screen and (min-width: $xxs) and (max-width: $sm) {
                    padding: 40px 10px 10px;
                }

                img {
                    width: 100%;
                    max-width: 150px;
                    max-height: 100%;
                    object-fit: contain;
                }
            }

            .item_box_content {
                padding: 10px 10px;
                color: var(--bs-body-color);
                height: 60.5px;

                h5,
                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &.selected_item {
                border-color: rgba(var(--theme-blue-rgb), .3);
            }
        }
    }

    &.dynamic_card {
        .item_box {
            margin-bottom: 20px;

            @media screen and (min-width: $xxs) {
                flex: 0 0 auto;
                width: 50%;
            }

            @media screen and (min-width: 630px) {
                max-width: 33.33%;
            }

            @media screen and (min-width: 921px) and (max-width: 1199px) {
                max-width: 25%;
            }

            @media screen and (min-width: 1200px) {
                max-width: 20%;
            }

            @media screen and (min-width: 1600px) {
                max-width: 14.285%;
            }

            @media screen and (min-width: 1920px) {
                max-width: 220px;
            }
        }
    }

    &.type_2 {
        .item_box {
            .item_box_inner {
                .item_box_img {
                    padding: 20px;
                }

                .item_box_content {
                    height: 39.5px;
                }
            }
        }
    }
}

.custom_panel_wrap {
    position: fixed;
    top: 0;
    z-index: 1049;
    left: 0;

    >div {
        z-index: 10;
    }

    .custom_panel {
        position: fixed;
        top: 0;
        bottom: 0;
        height: 100vh;
        background: #ffffff;
        width: 100%;
        z-index: 1045;
        left: unset;
        transform: translateX(-630px);
        transition: transform .3s ease-in-out;

        @media screen and (min-width: $sm) {
            transform: translateX(-530px);
            width: 530px;
        }
    }

    &.sidebar-open {
        .custom_panel {
            transform: translateX(0);
        }

        &:before {
            content: '';
            background: rgba(0, 0, 0, .3);
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 1;
        }
    }
}

.panel_item {
    transition: background-color 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    // margin: 10px 0;
    padding: 4px 0 4px 6px;

    &:not(.frame_type)>div {
        @media screen and (max-width: ($sm - 1px)) {
            flex-direction: column;
            align-items: start !important;
        }
    }

    .img_wrap {
        min-width: 40px;
        width: 40px;
        height: 40px;
        max-width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .title {
        word-break: break-word;
    }

    &.selected {
        background: rgba(var(--theme-blue-rgb), .1);

        .title,
        svg {
            color: var(--theme-blue);
        }
    }

    &:hover {
        .title {
            color: var(--theme-blue);
        }
    }

    &.frame_type {
        border: 1px solid var(--bs-border-color);
        padding: 4px;
        margin: 0 0 10px 0;

        &.selected {
            border-color: var(--theme-blue);
        }
    }
}

.alert {
    border-radius: 8px;

    &.alert-secondary {
        border: none;
        background: #f5f5f5;
    }
}

.MuiPopover-root {
    z-index: 1351 !important;
}

.modal-open .MuiPopover-root {
    z-index: 9999 !important;
}

.sidebar_active .MuiPopover-root {
    z-index: 1045 !important;
}

.w-sm-50 {
    @media screen and (min-width: $sm) {
        width: 50% !important;
    }
}

.mb-3.mb-0 {
    margin-bottom: 0 !important;
}

// print page 
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0 !important;
}

@media print {
    .page-content {
        margin-left: 0 !important;
    }

    .no_print {
        display: none !important;
    }

    .unsetPrintHeight {
        height: unset;
    }

    .page_layout {
        box-shadow: none !important;
        margin-bottom: 0 !important;
        height: unset !important;
    }

    .page_section {
        padding: 0 !important;
    }
}

.has_x_scroll {
    overflow: auto;
    max-height: calc(100vh - 200px);
    width: 100%;
}

.MuiMenuItem-root {
    font-family: unset !important;
}

.frame_style_scroll {
    max-height: calc(100vh - 550px);
    overflow-y: auto;
    margin-right: -6px;
    padding-right: 6px;
}

.rdt_Table {

    .rdt_TableCell,
    .rdt_TableCol {
        padding: 5px 5px !important;
    }
}

// Start: Datatable
.rdt_TableHeadRow {
    background-color: rgba(var(--theme-blue-rgb), .1) !important;
    border-bottom: none !important;
    min-height: 40px !important;

    >div {
        // min-width: 120px;
        font-size: 0.8125rem !important;
        font-weight: 600 !important;
        white-space: nowrap !important;
        color: #495057 !important;
    }
}

.rdt_TableCol_Sortable {

    &:hover,
    span:first-child {
        opacity: 1 !important;
    }
}

// .rdt_TableBody {
//     height: calc(100vh - 382px);
//     overflow-y: auto;
// }

.profile_link {
    display: flex;
    flex-direction: column;

    .quote_list {
        flex: 1;
    }

    .rdt_TableBody {
        height: auto;
        overflow: hidden;
    }
}

.custom_datatable {
    thead {
        background-color: rgba(var(--theme-blue-rgb), .1) !important;
    }

    &.oversize_table {

        .rdt_TableHeadRow,
        .rdt_TableRow {
            >div:first-child {
                min-width: 250px;
                // >div {
                //     width: 100%;

                //     .badge {
                //         background-color: rgba(var(--bs-primary-rgb), .5) !important;
                //     }
                // }
            }

            >div:not(:first-child) {
                justify-content: center;
            }
        }

        .rdt_TableCell {
            div:first-child {
                white-space: normal;
            }
        }
    }
}

.rdt_TableRow {
    border-bottom: none !important;
    min-height: 40px !important;

    &:hover {
        background-color: #efefef;
    }
}

// End: Datatable

// layout without header footer
.layout_2 {
    .page-content {
        margin-left: 0 !important;
        padding: 18px !important;

        .left_content {
            min-height: calc(100vh - 92.34px);

            >.card {
                max-height: calc(100vh - 92.34px);
            }
        }
    }

    #page-topbar {
        display: none !important;
    }

    .vertical-menu {
        display: none !important;
    }
}

.dropdown-menu {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

    &.body_dropdown {
        max-width: -moz-fit-content;
        max-width: fit-content;
        transform: none !important;

        // can be changed for different dropdowns
        right: 65px !important;
        top: 150px !important;

        &.has_scroll {
            max-height: 200px;
            overflow-y: auto;
        }

        &.hardware_list {
            top: 350px !important;
        }

        &.panel_hardware_list {
            top: 210px !important;
        }
    }

    &.full_height {
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        min-width: 200px;
    }
}

.apertures_check {
    .MuiCheckbox-root {
        margin: 0 !important;
        padding-right: 10px !important;

        .MuiIconButton-label {
            width: 20px;
            height: 20px;
            display: inline-block;
            user-select: none;
            border: 1px solid var(--theme-blue);
            background: rgba(var(--theme-blue-rgb, .6));
            border-radius: 2px;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        &.Mui-checked {
            ~.MuiCheckbox-root .MuiIconButton-label {
                background: #ffffff;
                border-color: var(--bs-border-color);
            }

            .MuiIconButton-label {
                border-color: var(--theme-blue) !important;
                background: var(--theme-blue) !important;
            }
        }

        svg {
            display: none;
        }
    }

    &:hover {
        .MuiCheckbox-root .MuiIconButton-label {
            border-color: var(--theme-blue) !important;
            background: var(--theme-blue) !important;
        }

        .MuiCheckbox-root:hover~.MuiCheckbox-root .MuiIconButton-label {
            background: #ffffff !important;
            border-color: var(--bs-border-color) !important;
        }
    }
}

.btn.ref_btn {
    // border: 1px solid #989898;
    border: none;
    padding: 0;
    width: 32px;
    height: 32px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important;

    &.btn-light {
        background: #ffffff;
        color: var(--bs-body-color);
    }

    &.btn-primary {
        color: #ffffff;
        border-color: var(--theme-blue);
        background: var(--theme-blue);

        svg {
            color: #ffffff !important;
        }
    }

    &.lock_btn {
        border: 1px solid transparent;
        width: 18px;
        height: 18px;
        z-index: 1;
        top: -12px;
        right: -12px;
        color: #989898;

        &.is_left {
            transform: rotate(-90deg);
        }

        &.btn-outline-danger {
            color: #f46a6a !important;
            border-color: #f46a6a !important;
            background: #ffffff !important;
        }
    }

    &.type_2 {
        height: 24px;
        border-radius: 4px !important;
        font-size: 13px;
        padding: 0px 10px !important;
        width: auto;
        background: var(--theme-blue);
        color: #fff;
        border: none;
        z-index: 2;
        box-shadow: none !important;

        span {
            line-height: 1;
            margin-top: 2px;
        }
    }
}

.btn_parent {
    &.is_left {
        transform: translateX(-8px) rotate(90deg);
    }

    &.ref_sq_left {
        transform: rotate(-90deg) translateY(-5px);
    }
}

.pricing_table {
    tr td {
        padding: 0.5rem 0.25rem;

        &:first-child {
            min-width: 30%;
            max-width: 160px;
        }

        &:nth-child(2) {
            min-width: 50%;
            max-width: 250px;
        }

        &:nth-child(3) {
            min-width: 20%;
            max-width: 100px;
        }
    }
}

.td_p_2.table tr td {
    padding: 0.5rem 0.25rem;
}

.model_hidden {
    opacity: 0;
}

.add_ancillary_btn {
    >button {
        outline: 0 !important;
        box-shadow: none !important;
        border: none !important;
        font-weight: 500;

        &:hover {
            background: rgba(var(--theme-blue-rgb), .1);
        }

        &:nth-child(2) {
            &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 1.5px;
                height: 80%;
                top: 10%;
                background-color: var(--bs-border-color);
            }
        }
    }
}

// .check_box_wrap {
//     gap: 10px;
//     flex-wrap: wrap;
//     justify-content: center;
//     @media only screen and (max-width: 576px) {
//         justify-content: space-around;
//     }

//     .check_box_item {
//         display: flex;
//         padding: 0;
//         background: #FFFFFF;
//         border: none;
//         width: fit-content;
//         cursor: pointer;
//         display: flex;
//         align-items: center;
//         position: relative;

//         .label_name {
//             padding: 10px 18px;
//             background: #FFFFFF;
//             border: 1px dashed #d1d1d1;
//             border-radius: 8px;
//             font-size: 14px;
//             line-height: 16px;
//             color: #333333;
//             font-weight: 600;
//             text-align: center;
//             min-width: 105px;
//             max-width: 112px;
//             width: 100%;
//             white-space: nowrap;
//         }

//         input {
//             position: absolute;
//             z-index: -1;
//             display: none;

//             &::after {
//                 display: none;
//             }

//             &:checked~.label_name {
//                 background: rgba(var(--theme-blue-rgb), .2);
//                 border-color: var(--theme-blue);
//                 color: var(--theme-blue);
//             }
//         }

//         &.type_2 {
//             .label_name {
//                 padding: 8px 12px;
//                 border-style: solid;
//                 text-align: left;
//                 width: fit-content;
//                 white-space: nowrap;
//                 max-width: 100%;

//                 h5, p {
//                     margin-bottom: 0 !important;
//                     color: #333333 !important;
//                 }

//                 p {
//                     font-weight: normal;
//                 }
//             }
//         }
//     }

//     .tooltip_icon {
//         position: absolute;
//         right: 8px;
//         margin-top: 1px;
//     }
// }

.ref_btn_wrap {
    position: fixed;
    top: 22.5px;
    left: 100px;
}

.designer-right-menu {
    .form-group.mb-2.mb-3 {
        margin-bottom: 1rem !important;
    }
}

.glaze_img {
    position: absolute;
    width: 90% !important;
    height: 90% !important;
    object-fit: contain !important;
    margin: 5%;
}

.manufacturing_scroll {
    overflow-y: auto;
    max-height: calc(100vh - 500px);
}

.style_svg {
    svg {
        path {
            color: #000000 !important;
            stroke: #000000 !important;
        }
    }
}

.visibility_on,
.visibility_off {
    display: none;
}

.quote_edit_form {
    // left: 80px;
    // position: fixed;
    // top: ($sidebar_w + 60px);

    // @media screen and (max-width: ($sm - 1px)) {
    //     top: ($sidebar_w_s + 60px);
    // }

    // >.popover {
    //     position: relative !important;
    //     transform: none !important;
    //     min-width: 300px !important;

    //     .arrow {
    //         left: 60px !important;
    //         transform: unset !important;
    //     }
    // }

    .scroll_area {
        min-width: 280px;
        max-height: calc(100vh - 385px);
        overflow-y: auto;
        margin-right: -15px;
        padding-right: 10px;
    }
}

// .popover {
//     --bs-popover-font-size: 14px
// }

.circle_cross {
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.box_right_top {
        position: absolute;
        right: -10px;
        top: -10px;
    }
}

.custom_shadow {
    .popover {
        --bs-popover-border-color: #e5e5e5;
        max-width: 250px;
        // box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    }

    .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
    .bs-popover-bottom>.popover-arrow::before {
        --bs-popover-arrow-border: #d5d5d5;
    }
}

// ref points dropdown fixes
.popover-inner {
    .dropdown {
        .dropdown-menu {
            max-height: 200px;
            overflow-y: auto;
        }
    }
}

.back_btn,
.designer_btn {
    // padding: 0.47rem 0.75rem;
    padding: 0.35rem .75rem .35rem 0.45rem;
    color: #ffffff !important;
}

#elements {
    visibility: visible;
    opacity: 1;
    transition: .3s all ease-in-out;

    &.hide {
        visibility: hidden;
        opacity: 0;
    }
}

.main_layout.page_scroll .left_content.full .inside_scroll {
    .quote_list {
        max-width: 100%;
        margin-bottom: 10px;

        .rdt_TableBody {
            min-height: 250px;
            max-height: calc(100vh - 392px);
            max-width: 100%;
        }
    }
}

.rdt_Pagination {
    select {
        height: 24px !important;
    }

    >div:last-child {
        gap: 5px;

        button {
            padding: 0;
            width: 35px;
            height: 35px;
            background: #fff;
            border: 1px solid var(--bs-border-color);

            &:not(:disabled):hover {
                background: var(--theme-blue);
                border-color: var(--theme-blue);

                svg {
                    fill: #ffffff;
                }
            }
        }
    }
}

// .status_indicator {
//     height: 10px;
//     width: 10px;
//     border-radius: 50%;
//     margin-right: 6px !important;
//     position: relative;
//     display: inline-flex;
//     justify-content: center;
//     align-items: center;
//     background-color: var(--bg-indi-color);

//     &:after {
//         content: '';
//         width: 16px;
//         height: 16px;
//         border-radius: 50%;
//         position: absolute;
//         background-color: var(--bg-indi-color-a);
//     }

//     &.bg-danger {
//         &:after {
//             background-color: rgba(var(--bs-danger-rgb), .3);
//         }
//     }

//     &.bg-success {
//         &:after {
//             background-color: rgba(var(--bs-success-rgb), .3);
//         }
//     }

//     &.bg-warning {
//         &:after {
//             background-color: rgba(var(--bs-warning-rgb), .3);
//         }
//     }

//     &.bg-primary {
//         &:after {
//             background-color: rgba(var(--bs-primary-rgb), .3);
//         }
//     }

//     &.bg-info {
//         &:after {
//             background-color: rgba(var(--bs-info-rgb), .3);
//         }
//     }
// }

.status_indicator {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-indi-color-a);

    &:after {
        content: '';
        background-color: var(--bg-indi-color);

        height: 10px;
        width: 10px;
        min-width: 10px;
        min-height: 10px;
        border-radius: 50%;
    }
}

// .canvas_wrap {
//     height: calc(100vh - 120px);
// }

// .hidden_model {

// }

.model_wrap {
    // width: 800px;
    // margin: 0 auto 0;
    height: calc(100vh - 112px);
    display: flex;
    justify-content: center;
    align-items: center;

    >div:first-child {
        max-height: 100vh;
    }
}

.accordion_btn_custom {
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: transparent;
    --bs-accordion-btn-icon: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--bs-body-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
    --bs-accordion-btn-active-icon: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--bs-body-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
    --bs-accordion-btn-icon-width: 16px;
    --bs-accordion-btn-icon-transform: rotate(180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;

    padding: 5px 0;
    font-size: 17px;
    min-height: 40px;
}

.saving_images {
    .btn.ref_btn {

        &.type_2 {
            background: transparent;
            color: #000000;
            font-size: 15px;
        }

        &.lock_btn {
            top: -8px;
            right: -10px;
            z-index: 4;
        }
    }

    .btn_parent {
        &.is_left {
            transform: translateX(-15px) rotate(90deg);
        }

        &.ref_sq_left {
            transform: rotate(-90deg) translate(-5px, -20px);
        }

        &.is_right {
            transform: translateX(15px);
        }

        &.ref_sq_top {
            transform: translateY(-14px);
        }

        &.is_bottom {
            transform: translate(-40%, 10px);
        }
    }

    &.hide_lock {
        .btn_parent.is_bottom {
            transform: translate(0, 10px);
        }

        .lock_btn {
            display: none !important;
        }
    }
}