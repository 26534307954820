.palette-color-picker .photoshop-picker {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
}

.palette-color-picker .photoshop-picker>div {
    display: none;
}

.sliding-panel-container .glass {
    width: 0 !important;
}

.panel_object_list {
    .right_item {
        display: flex;
        align-items: center;
        color: var(--dark-text);
        position: relative;
        padding: 10px 16px 10px 0;
        // height: 100%;
        // overflow-y: auto;

        span:last-child {
            font-size: 15px;
        }

        .image_icon {
            max-width: 60px;
            max-height: 40px;
            display: flex;

            svg {
                width: 100%;
                height: auto;
            }

            img {
                width: auto;
                max-width: 100%;
                object-fit: contain;
                max-height: 40px;
            }
        }

        &.active {
            color: var(--theme-blue);
            background: rgba(var(--theme-blue-rgb), .08);

            .small_svg_set {
                svg {

                    &:not(.fill) {
                        fill: none;
                    }

                    .stroke {
                        stroke: var(--theme-blue);
                    }
                }
            }
        }

        &:hover {
            color: var(--theme-blue);
        }

        .color_circle {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            border-radius: 50%;

            &.dot_lg {
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
            }
        }

        .right_title {
            display: grid;

            span {
                line-height: 1.4;
            }
        }
    }

    .action_btns {
        display: none;
        gap: 5px;
        right: 10px;

        a:hover svg {
            color: var(--theme-blue) !important;
        }
    }

    .right_item_wrap {
        &:hover {
            .right_item {
                color: var(--theme-blue);
                background: #f9f9f9;
            }

            .action_btns {
                display: flex;
            }

            &.has_delete,
            &.has_copy {
                .right_item {
                    padding-right: 34px;
                }
            }

            &.has_delete.has_copy {
                .right_item {
                    padding-right: 55px;
                }
            }
        }
    }
}

.second-modal{
    .modal {
        --bs-modal-zindex: 9999
    }
}

.bg-purple {
    background-color: #E3008C;
}
