.palette-color-picker .photoshop-picker {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.palette-color-picker .photoshop-picker > div {
  display: none;
}

.sliding-panel-container .glass {
  width: 0 !important;
}

.panel_object_list .right_item {
  display: flex;
  align-items: center;
  color: var(--dark-text);
  position: relative;
  padding: 10px 16px 10px 0;
}
.panel_object_list .right_item span:last-child {
  font-size: 15px;
}
.panel_object_list .right_item .image_icon {
  max-width: 60px;
  max-height: 40px;
  display: flex;
}
.panel_object_list .right_item .image_icon svg {
  width: 100%;
  height: auto;
}
.panel_object_list .right_item .image_icon img {
  width: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 40px;
}
.panel_object_list .right_item.active {
  color: var(--theme-blue);
  background: rgba(var(--theme-blue-rgb), 0.08);
}
.panel_object_list .right_item.active .small_svg_set svg:not(.fill) {
  fill: none;
}
.panel_object_list .right_item.active .small_svg_set svg .stroke {
  stroke: var(--theme-blue);
}
.panel_object_list .right_item:hover {
  color: var(--theme-blue);
}
.panel_object_list .right_item .color_circle {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
}
.panel_object_list .right_item .color_circle.dot_lg {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}
.panel_object_list .right_item .right_title {
  display: grid;
}
.panel_object_list .right_item .right_title span {
  line-height: 1.4;
}
.panel_object_list .action_btns {
  display: none;
  gap: 5px;
  right: 10px;
}
.panel_object_list .action_btns a:hover svg {
  color: var(--theme-blue) !important;
}
.panel_object_list .right_item_wrap:hover .right_item {
  color: var(--theme-blue);
  background: #f9f9f9;
}
.panel_object_list .right_item_wrap:hover .action_btns {
  display: flex;
}
.panel_object_list .right_item_wrap:hover.has_delete .right_item, .panel_object_list .right_item_wrap:hover.has_copy .right_item {
  padding-right: 34px;
}
.panel_object_list .right_item_wrap:hover.has_delete.has_copy .right_item {
  padding-right: 55px;
}

.second-modal .modal {
  --bs-modal-zindex: 9999 ;
}

.bg-purple {
  background-color: #E3008C;
}/*# sourceMappingURL=style.css.map */