@charset "UTF-8";
:root {
  --theme-blue: #44C8F5;
  --theme-blue-rgb: 66, 200, 245;
  --bs-blue-hover: rgba(var(--bs-blue-rgb), .9);
  --bs-body-color: #495057;
  --bs-heading-color: #495057;
  --bs-blue: var(--theme-blue);
  --bs-primary: var(--theme-blue);
  --bs-link-color: var(--theme-blue);
  --bs-blue-rgb: var(--theme-blue-rgb);
  --theme-black: #334155;
  --text-global: #64748b;
  --dark-text: #495057;
  --bs-border-color: #dfdfdf;
  --bs-input-border-color: #ced4da;
  --text-muted: #74788d;
  --readonly-color: #f0f0f0;
}

.btn {
  height: 36.53px;
  --bs-btn-disabled-opacity: .6;
}
@media screen and (max-width: 575px) {
  .btn {
    --bs-btn-font-size: 14px;
  }
}
.btn.rounded {
  border-radius: 30px !important;
}
.btn.btn-outline-primary:active, .btn.btn-outline-primary:focus {
  background: unset;
  color: var(--theme-blue);
}
.btn.btn-outline-primary:hover {
  background: var(--theme-blue);
  color: #ffffff;
}

a.disabled {
  opacity: 0.6;
  cursor: auto !important;
}
a.nav-link:hover {
  color: var(--theme-blue);
}

.dropdown-item:hover {
  cursor: pointer;
  color: var(--theme-blue);
}

.border-left {
  border-left: 1px solid var(--bs-border-color);
}

.text-muted {
  color: var(--text-muted) !important;
}

.text-muted-light {
  color: #c2c2c2 !important;
}

.main_buttons {
  align-items: center;
}
.main_buttons .btn {
  display: flex;
  align-items: center;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}
body #layout-wrapper {
  min-height: 100vh;
}
body #layout-wrapper .main-content {
  transition: all 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body #layout-wrapper .main-content .page-content {
  min-height: 100vh;
}
body #layout-wrapper .main-content > .footer {
  padding-left: 94px;
  position: unset;
}
@media screen and (min-width: 9999px) {
  body #layout-wrapper.sidebar-lg .main-content {
    margin-left: 180px;
  }
}
body #layout-wrapper.sidebar-lg .navbar-header .logo_large {
  display: none;
}
body.pdf_view .page-main header,
body.pdf_view .page-main .vertical-menu {
  display: none;
}
body.pdf_view .page-content {
  padding: 18px !important;
  margin-left: 0 !important;
}
body.pdf_view .page-content .quote_pdf.has_x_scroll {
  max-height: 100%;
}

.fw-500 {
  font-weight: 500 !important;
}

.text-small,
small {
  font-size: 12px !important;
}

/* custom scroll bar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background: #e6e6e6;
}

::-webkit-scrollbar-track {
  border-radius: 12px;
}

::-webkit-scrollbar-track:hover {
  background: #e6e6e6;
}

::-webkit-scrollbar-thumb {
  background: rgba(207, 202, 202, 0.7);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(207, 202, 202);
}

.text-dark {
  color: var(--dark-text);
}

.br-4 {
  border-radius: 4px;
}
.br-8 {
  border-radius: 8px;
}

.custom_x_gap {
  gap: 0 20px;
}

.page-content {
  margin-left: 70px;
}
@media screen and (max-width: 575px) {
  .page-content {
    margin-left: 50px;
    padding-top: 74px;
  }
}

.container-fluid {
  padding: 0 !important;
}

.footer {
  left: 70px;
}
@media screen and (max-width: 575px) {
  .footer {
    left: 50px;
  }
}

.card {
  border-radius: 10px;
}
.card.shadow-2 {
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
}
.card.light_border {
  border: 1px solid #ebebeb;
}
.card.setting_card:hover {
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
}
.card .card-body:not(.main-card) {
  padding: 1rem;
}
@media screen and (max-width: 575px) {
  .card .card-body:not(.main-card) {
    padding: 12px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.grey-text {
  color: #959699;
}

input,
button,
.btn,
.form-control,
select {
  outline: none !important;
  box-shadow: none !important;
}

button,
.btn {
  text-wrap: nowrap;
}

.btn-light {
  --bs-btn-hover-bg: #efefef;
}

@media screen and (min-width: 480px) {
  .d-xs-none {
    display: none !important;
  }
}

@media screen and (min-width: 480px) {
  .d-xs-block {
    display: block !important;
  }
}

.clickable,
a {
  cursor: pointer;
}

.form-check-input {
  background-color: var(--bs-input-border-color) !important;
}
.form-check-input:checked {
  color: #fff;
  background-color: var(--theme-blue) !important;
}

.w-7 {
  width: 2rem;
}

.h-7 {
  height: 2rem;
}

.fill-primary {
  fill: var(--theme-blue);
}

.dot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin-right: 6px !important;
  display: inline-block;
}

.logo_large {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  width: 100%;
  max-width: 200px;
  height: 70px;
}
@media screen and (max-width: 575px) {
  .logo_large {
    height: 50px;
  }
}
.logo_large img {
  width: unset;
  max-width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 55px;
}
@media screen and (max-width: 575px) {
  .logo_large img {
    max-height: 35px;
  }
}

.vertical-menu {
  background-color: var(--theme-blue);
  width: 70px;
  top: 70px;
  transition: all 0.4s ease-in-out;
}
@media screen and (max-width: 575px) {
  .vertical-menu {
    width: 50px;
    top: 50px;
  }
}
.vertical-menu #sidebar-menu {
  padding: 15px 10px 0 15px !important;
  background-color: var(--theme-blue);
  height: 100%;
}
@media screen and (max-width: 575px) {
  .vertical-menu #sidebar-menu {
    padding: 15px 5px 0 5px !important;
  }
}
.vertical-menu #sidebar-menu .multi_level_sidebar {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -6px;
  padding-right: 6px;
  /* custom scroll bar */
}
.vertical-menu #sidebar-menu .multi_level_sidebar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.vertical-menu #sidebar-menu .multi_level_sidebar::-webkit-scrollbar-corner, .vertical-menu #sidebar-menu .multi_level_sidebar::-webkit-scrollbar-track {
  background: transparent;
}
.vertical-menu #sidebar-menu .multi_level_sidebar::-webkit-scrollbar-track {
  border-radius: 12px;
}
.vertical-menu #sidebar-menu .multi_level_sidebar::-webkit-scrollbar-track:hover {
  background: rgba(255, 255, 255, 0.3);
}
.vertical-menu #sidebar-menu .multi_level_sidebar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 12px;
}
.vertical-menu #sidebar-menu .MuiButtonBase-root {
  padding: 4px 2px 4px 4px;
  max-width: 100%;
  border-radius: 4px;
  margin-bottom: 8px;
  width: unset;
  min-width: 38px;
}
.vertical-menu #sidebar-menu .MuiButtonBase-root * {
  pointer-events: none;
}
.vertical-menu #sidebar-menu .MuiButtonBase-root > svg {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}
.vertical-menu #sidebar-menu .MuiButtonBase-root > svg path {
  fill: #ffffff;
}
.vertical-menu #sidebar-menu .MuiButtonBase-root .MuiListItemIcon-root {
  min-width: unset;
}
.vertical-menu #sidebar-menu .MuiButtonBase-root .MuiListItemText-root {
  margin: 0 !important;
  white-space: nowrap;
  overflow: hidden;
}
.vertical-menu #sidebar-menu .MuiButtonBase-root .menu_icon {
  width: 30px;
  height: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.vertical-menu #sidebar-menu .MuiButtonBase-root .menu_icon img {
  width: 25px;
  height: 25px;
  max-height: 100%;
}
.vertical-menu #sidebar-menu .MuiButtonBase-root.mm-active > svg path {
  fill: var(--theme-black);
}
.vertical-menu #sidebar-menu .MuiButtonBase-root.mm-active.initial_active.test_0 svg.down_icon {
  transform: rotate(180deg);
}
.vertical-menu #sidebar-menu .MuiCollapse-container {
  max-width: calc(100% - 30px);
  margin-left: auto;
  width: 100%;
}
.vertical-menu #sidebar-menu .MuiCollapse-container .MuiCollapse-container {
  max-width: calc(100% - 10px);
}
.vertical-menu #sidebar-menu .MuiListItemText-root > span {
  color: #ffffff;
  font-family: inherit;
  font-size: 18px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inherit;
}
.vertical-menu #sidebar-menu .sub_menu .MuiListItemText-root > span {
  color: var(--theme-black);
}
.vertical-menu #sidebar-menu .sub_menu > svg path {
  fill: var(--theme-black);
}
.vertical-menu #sidebar-menu .sub_menu.sub_menu_3 .MuiListItemText-root > span, .vertical-menu #sidebar-menu .sub_menu.sub_menu_4 .MuiListItemText-root > span {
  font-size: 16px;
}
.vertical-menu #sidebar-menu .sub_menu.sub_menu_3.MuiButtonBase-root, .vertical-menu #sidebar-menu .sub_menu.sub_menu_4.MuiButtonBase-root {
  padding: 3px 0px 3px 4px;
}
.vertical-menu #sidebar-menu .sub_menu.sub_menu_3.MuiButtonBase-root > svg, .vertical-menu #sidebar-menu .sub_menu.sub_menu_4.MuiButtonBase-root > svg {
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
}
.vertical-menu #sidebar-menu .sub_menu.sub_menu_3.mm-active:not(.page_links) {
  background-color: rgba(255, 255, 255, 0.22);
}
.vertical-menu #sidebar-menu .mm-active .MuiListItemText-root > span {
  color: var(--theme-black);
}
.vertical-menu #sidebar-menu .mm-active svg path,
.vertical-menu #sidebar-menu .mm-active svg * {
  fill: var(--theme-black) !important;
}
.vertical-menu #sidebar-menu .mm-active .menu_icon img {
  filter: brightness(20%);
}
.vertical-menu #sidebar-menu .mm-active .menu_name {
  color: var(--theme-black);
}
.vertical-menu #sidebar-menu .mm-active.main_menu {
  background-color: #ffffff;
}
.vertical-menu #sidebar-menu .mm-active.sub_menu:not(.page_links) {
  background-color: rgba(255, 255, 255, 0.42);
}
.vertical-menu #sidebar-menu .mm-active.sub_menu.page_links {
  background-color: rgba(255, 255, 255, 0.1);
}
.vertical-menu #sidebar-menu .mm-active.sub_menu.page_links .MuiListItemText-primary {
  color: #ffffff;
}
.vertical-menu .toggle_back,
.vertical-menu .sidebar_toggle {
  position: absolute;
  border-radius: 50%;
}
.vertical-menu .sidebar_toggle {
  height: 70px;
  right: -19px;
  top: 0vh;
  display: none;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.vertical-menu .sidebar_toggle svg:first-child {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  right: -1px;
}
.vertical-menu .sidebar_toggle svg:first-child * {
  fill: var(--theme-blue);
}
.vertical-menu .sidebar_toggle.rect_btn {
  border-radius: 4px;
  width: 35px;
  height: 60px;
  right: -15px;
}
.vertical-menu .sidebar_toggle svg:last-child {
  transition: all 0.4s ease-in-out;
  transform: rotate(0);
}
.vertical-menu.sidebar-lg {
  width: 250px;
}
.vertical-menu.sidebar-lg .sidebar_toggle svg:last-child {
  transform: rotate(180deg);
}
.vertical-menu.sidebar-lg .side-navigation-panel-select-option-text {
  width: 100%;
  transition: width 0.4s ease-in-out;
}
.vertical-menu.sidebar-lg .MuiButtonBase-root.MuiListItem-root.mm-active.initial_active.test_0.main_menu + .MuiCollapse-container, .vertical-menu.sidebar-lg .MuiButtonBase-root.MuiListItem-root.mm-active.initial_active.test_0.sub_menu + .MuiCollapse-container {
  min-height: 0px;
  height: auto;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-duration: 283ms;
  visibility: visible;
}
.vertical-menu.sidebar-sm .side-navigation-panel-select-option-text {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.vertical-menu.sidebar-sm .side-navigation-panel-select-option-icon {
  display: none !important;
}
.vertical-menu.sidebar-sm .MuiListItemText-root {
  display: none;
}
.vertical-menu.sidebar-sm .MuiButtonBase-root {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.vertical-menu.sidebar-sm .MuiButtonBase-root > svg {
  display: none;
}
.vertical-menu.sidebar-sm .MuiCollapse-container {
  position: absolute;
  z-index: -1;
}
.vertical-menu .logo_large,
.vertical-menu .dash_small_logo {
  display: none;
}
.vertical-menu .dash_small_logo {
  width: 70px;
  height: 70px;
  max-height: 70px;
  min-width: 70px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .vertical-menu .dash_small_logo {
    width: 50px;
    height: 50px;
    max-height: 50px;
    min-width: 50px;
  }
}
.vertical-menu .dash_small_logo .logo-lg {
  display: flex !important;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  max-width: 100%;
}
.vertical-menu .dash_small_logo .logo-lg img {
  width: 90%;
  height: 90% !important;
  max-height: 90%;
  -o-object-fit: cover;
     object-fit: cover;
}
.vertical-menu.full_sidebar {
  z-index: 1048;
  top: 0;
}
.vertical-menu.full_sidebar #sidebar-menu {
  height: calc(100vh - 70px);
}
@media screen and (max-width: 575px) {
  .vertical-menu.full_sidebar #sidebar-menu {
    height: calc(100vh - 50px);
  }
}
.vertical-menu.full_sidebar .sidebar_toggle {
  top: 70px;
}
@media screen and (max-width: 575px) {
  .vertical-menu.full_sidebar .sidebar_toggle {
    top: 50px;
  }
}
.vertical-menu.full_sidebar .logo_large {
  display: flex;
  width: 90%;
}
.vertical-menu.full_sidebar .logo_large img {
  filter: brightness(0) invert(1);
}
.vertical-menu:hover .multi_level_sidebar::-webkit-scrollbar-corner, .vertical-menu:hover .multi_level_sidebar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.3) !important;
}
.vertical-menu:hover .multi_level_sidebar::-webkit-scrollbar-thumb {
  background: #f7f0f0 !important;
}
.vertical-menu:hover .multi_level_sidebar::-webkit-scrollbar-thumb:hover {
  background: #ffffff !important;
}

body.is_touch .vertical-menu .sidebar_toggle {
  display: flex;
}
body.is_touch .page_title {
  padding-left: 16px;
}
body .page_title {
  padding-left: 16px;
}
body .page_title .right_master {
  min-width: 320px;
}
@media screen and (max-width: 991px) {
  body .page_title .right_master {
    min-width: 280px;
  }
}
@media screen and (max-width: 767px) {
  body .page_title .right_master {
    min-width: unset;
  }
}
body .page_title .right_master button span.fw-semibold {
  white-space: nowrap;
}
body .page_title .right_master > div.d-flex {
  flex-wrap: wrap;
}

@keyframes blink {
  from {
    scale: 0.6;
  }
  to {
    scale: 1.1;
  }
}
@keyframes leftRight {
  from {
    left: 0px;
  }
  to {
    left: 10px;
  }
}
.table_wrap {
  overflow: hidden;
}

table td,
table th {
  white-space: nowrap;
  vertical-align: middle;
}
table td .btn.btn-icon {
  width: 30px;
  height: 30px;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
table td .btn.btn-icon i {
  font-size: 12px;
}

form .form-control,
.form_layout .form-control {
  font-size: 14px;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  color: var(--dark-text);
}
form .form-control:-moz-read-only, .form_layout .form-control:-moz-read-only {
  background: var(--readonly-color);
}
form .form-control:read-only,
.form_layout .form-control:read-only {
  background: var(--readonly-color);
}
form textarea,
.form_layout textarea {
  line-height: 1.2;
}
form select,
form input.form-control,
.form_layout select,
.form_layout input.form-control {
  height: 36.53px !important;
}
form select.form-control,
.form_layout select.form-control {
  padding: 0.375rem 1rem 0.375rem 0.75rem !important;
}
form .input-group,
.form_layout .input-group {
  border-radius: 5px;
}
form .input-group .input-group-text,
.form_layout .input-group .input-group-text {
  background: transparent !important;
  border-radius: 5px 0 0 5px;
  height: 100% !important;
  padding: 0;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: var(--text-global);
}
form .input-group input.form-control::-ms-input-placeholder, form .input-group input.form-control:-ms-input-placeholder, form .input-group input.form-control::placeholder,
.form_layout .input-group input.form-control::-ms-input-placeholder,
.form_layout .input-group input.form-control:-ms-input-placeholder,
.form_layout .input-group input.form-control::placeholder {
  color: #989898;
  opacity: 1;
}
form .input-group-preappend .input-group-text,
.form_layout .input-group-preappend .input-group-text {
  border-radius: 5px 0 0 5px;
}
form .input-group-preappend input,
.form_layout .input-group-preappend input {
  padding: 0.375rem 0.75rem;
}
form .input-group-append .input-group-text,
.form_layout .input-group-append .input-group-text {
  border-radius: 0 5px 5px 0;
}
form .input-group svg,
form .input-group img,
.form_layout .input-group svg,
.form_layout .input-group img {
  width: 25px;
  height: 25px;
}
form .input-group svg.btn-acc-icon.hide_check,
form .input-group img.btn-acc-icon.hide_check,
.form_layout .input-group svg.btn-acc-icon.hide_check,
.form_layout .input-group img.btn-acc-icon.hide_check {
  z-index: 4;
  background: #fff;
}
form .input-group svg g,
form .input-group svg path,
form .input-group img g,
form .input-group img path,
.form_layout .input-group svg g,
.form_layout .input-group svg path,
.form_layout .input-group img g,
.form_layout .input-group img path {
  fill: var(--gray-e9);
}
form .input-group.search_box svg,
form .input-group.search_box img,
.form_layout .input-group.search_box svg,
.form_layout .input-group.search_box img {
  width: 20px;
  height: 20px;
}
form .input-group.search_box.input-group svg path,
.form_layout .input-group.search_box.input-group svg path {
  fill: transparent !important;
}
form .Sport__list .sport_item input,
.form_layout .Sport__list .sport_item input {
  height: unset !important;
}
form .trans_input input,
.form_layout .trans_input input {
  color: transparent !important;
  background: transparent !important;
}

.choices_select {
  position: relative;
  display: flex;
  align-items: center;
}
.choices_select .choices {
  width: 100%;
  min-width: 100px;
  margin-bottom: 0 !important;
  position: relative;
  z-index: 2;
}
.choices_select .choices .choices__inner {
  min-height: 36.52px;
  padding: 6px 8px;
  font-size: 1rem;
  border-radius: 4px;
  border-color: var(--bs-border-color);
  background-color: transparent;
}
.choices_select .choices .choices__inner .choices__input {
  padding: 2px 0 0px 2px;
}
.choices_select .choices .choices__item {
  padding-right: 0 !important;
}
.choices_select .choices .choices__item::after {
  display: none;
}
.choices_select .choices .choices__button {
  font-size: 14px;
  border-left-color: #ffffff;
  filter: invert(1) grayscale(100%) brightness(200%);
  opacity: 0.5 !important;
}
.choices_select .choices .choices__button:hover {
  opacity: 1 !important;
  color: var(--bs-danger);
}
.choices_select .choices .choices__list.choices__list--dropdown {
  border-color: var(--bs-border-color) !important;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important;
}
.choices_select .choices .choices__list.choices__list--dropdown .choices__item {
  padding: 5px 10px !important;
}
.choices_select .choices .choices__list.choices__list--dropdown .choices__input {
  background: transparent;
  border-color: var(--bs-border-color);
  padding: 6px 10px !important;
}
.choices_select .choices .choices__list.choices__list--dropdown .choices__list {
  max-height: 220px;
}
.choices_select .choices .choices__list.choices__list--multiple .choices__item {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  padding-right: 5px !important;
  white-space: nowrap;
}
.choices_select .choices .choices__list.choices__list--single {
  padding: 0;
  font-size: 14px;
}
.choices_select .choices .choices__list.choices__list--single .choices__item.choices__item--selectable {
  background: transparent !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.choices_select .choices[data-type*=select-one]::after {
  display: none;
}
.choices_select .choices[data-type*=select-one] .choices__inner {
  padding: 5px 30px 5px 8px !important;
  display: flex;
  align-items: center;
}
.choices_select .choices[data-type*=select-one] .choices__item--selectable.is-selected {
  background-color: rgba(var(--bs-primary-rgb), 0.15) !important;
  color: var(--bs-primary);
}
.choices_select .select_icon {
  position: absolute;
  right: 10px;
  z-index: 1;
  transform: rotateZ(0deg);
  transition: transform 0.1s ease;
}
.choices_select .choices.is-open ~ .select_icon {
  transform: rotateZ(180deg);
}

.dot {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.dot:after {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
}
.dot.bg-danger:after {
  background-color: rgba(var(--bs-danger-rgb), 0.3);
}
.dot.bg-success:after {
  background-color: rgba(var(--bs-success-rgb), 0.3);
}
.dot.bg-warning:after {
  background-color: rgba(var(--bs-warning-rgb), 0.3);
}
.dot.bg-primary:after {
  background-color: rgba(var(--bs-primary-rgb), 0.3);
}
.dot.bg-info:after {
  background-color: rgba(var(--bs-info-rgb), 0.3);
}

.datepicker-panel ul li[data-view=day] {
  color: var(--theme-black);
}

.datepicker.dropdown-menu {
  padding: 0 !important;
}
.datepicker.dropdown-menu.datepicker-orient-right {
  left: unset !important;
  right: 20% !important;
}
.datepicker table {
  border-collapse: separate !important;
  border-spacing: 2px 2px !important;
}
.datepicker table tr th,
.datepicker table tr td {
  font-size: 14px !important;
}
.datepicker table .day,
.datepicker table .month,
.datepicker table .year,
.datepicker table .decade,
.datepicker table .century {
  color: #000000;
}
.datepicker table .day.active,
.datepicker table .month.active,
.datepicker table .year.active,
.datepicker table .decade.active,
.datepicker table .century.active {
  background-color: #333333 !important;
}
.datepicker table .day.disabled,
.datepicker table .month.disabled,
.datepicker table .year.disabled,
.datepicker table .decade.disabled,
.datepicker table .century.disabled {
  opacity: 0.5;
}
.datepicker table .day.old,
.datepicker table .month.old,
.datepicker table .year.old,
.datepicker table .decade.old,
.datepicker table .century.old {
  opacity: 0.3;
}

.btn-link {
  color: var(--dark-text);
  text-decoration: none !important;
}
.btn-link:hover {
  color: var(--theme-blue) !important;
}
.btn-link:hover.danger {
  color: var(--bs-danger) !important;
}

.user {
  position: relative;
}

.profile img {
  height: 80px;
  width: 80px;
  margin-top: 2px;
}

.profile {
  position: absolute;
  top: -50px;
  left: 38%;
  height: 90px;
  width: 90px;
  border: 3px solid #fff;
  border-radius: 50%;
}

.follow {
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px;
}

.stats span {
  font-size: 29px;
}

.bg-light {
  background-color: #E3F5FB !important;
}

.bg-light-gray {
  background-color: #f5f8f9 !important;
}

.btn-soft-secondary:active,
.btn-soft-secondary:focus,
.btn-soft-secondary:hover {
  background-color: #acafbf;
}

input::file-selector-button {
  height: 44px !important;
}

.pointer_none {
  cursor: initial;
}

.badge_fix .badge {
  line-height: 1.3;
  font-weight: normal;
  letter-spacing: 0.5px;
}

.datepicker {
  z-index: 999 !important;
}

.form-check.form-control .checkbox {
  opacity: 1;
}
.form-check.form-control .checkbox.form-check-input:disabled ~ .form-check-label, .form-check.form-control .checkbox.form-check-input[disabled] ~ .form-check-label {
  opacity: 1;
}
.form-check.form-switch .form-check-input:disabled {
  opacity: 0.8 !important;
}
.form-check.form-switch .form-check-input:checked:disabled {
  opacity: 0.5 !important;
}

.right-0 {
  right: 0px !important;
}

.btn-soft-primary {
  background-color: rgba(var(--bs-primary-rgb), 0.2);
}

.logo_200 {
  width: 200px;
  height: 200px;
  max-width: 200px;
  max-height: 200px;
}
.logo_200 img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.navbar-header {
  height: 70px;
}
@media screen and (max-width: 575px) {
  .navbar-header {
    height: 50px;
  }
}
.navbar-header .dropdown button span {
  font-weight: 500 !important;
  color: var(--bs-header-item-color) !important;
  display: flex;
  align-items: center;
}
.navbar-header .dropdown button span .text-truncate {
  max-width: 150px;
  display: block;
}
@media screen and (max-width: 479px) {
  .navbar-header .dropdown button span .text-truncate {
    max-width: 80px;
  }
}
.navbar-header .header-profile-user {
  width: 36px;
  height: 36px;
  border: 1px solid #e6e6e6;
}
.navbar-header .dropdown {
  height: 100%;
  display: flex;
  align-items: center;
}
.navbar-header .dropdown .dropdown-menu {
  right: 0 !important;
  left: auto !important;
  top: 100%;
}
@media screen and (max-width: 575px) {
  .navbar-header .dropdown .dropdown-menu {
    left: 18% !important;
    right: 10px !important;
    max-width: 80%;
  }
}
.navbar-header .logo_large {
  transform: translatex(70px);
  margin-left: 15px;
}
@media screen and (max-width: 575px) {
  .navbar-header .logo_large {
    transform: translatex(50px);
  }
}

.page_title {
  text-align: left;
  margin-bottom: 9.5px;
  gap: 10px;
  height: 36.53px;
}
.page_title h3 {
  font-size: 18px;
  text-transform: capitalize;
  line-height: 1.2;
  max-width: calc(100% - 40px);
  word-break: break-word;
  font-weight: 500;
}
.page_title .right_menu_btn {
  margin-left: auto;
  padding: 0 !important;
  height: unset;
}
.page_title .btn {
  height: unset;
  font-size: 13px;
}

h4,
.main_title {
  font-size: 16px !important;
  margin-bottom: 1rem;
  font-weight: 600;
}
h4.sub_title,
.main_title.sub_title {
  font-size: 15px !important;
}

.main_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.left_header {
  min-height: 19.19px;
}
.left_header h4 {
  font-size: 18px !important;
}

.sub_title {
  margin-bottom: 1rem;
}

.main_layout {
  position: relative;
}
.main_layout.page_scroll .left_content {
  min-height: calc(100vh - 169.34px);
}
@media screen and (max-width: 575px) {
  .main_layout.page_scroll .left_content {
    min-height: calc(100vh - 149.34px);
  }
}
.main_layout.page_scroll .left_content.full .inside_scroll {
  max-width: calc(100vw - 118px);
}
@media screen and (min-width: 576px) {
  .main_layout.page_scroll .left_content.full .inside_scroll {
    max-width: calc(100vw - 160px);
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .main_layout.page_scroll .left_content:not(.full) {
    padding-right: calc(280px + 1rem);
  }
}
@media screen and (min-width: 992px) {
  .main_layout.page_scroll .left_content:not(.full) {
    padding-right: calc(320px + 1rem);
  }
}
@media screen and (min-width: 1200px) {
  .main_layout.page_scroll .left_content:not(.full) {
    padding-right: calc(320px + 1.5rem);
  }
}
.main_layout.page_scroll .left_content:not(.full) .inside_scroll {
  max-width: calc(100vw - 118px);
}
@media screen and (min-width: 576px) {
  .main_layout.page_scroll .left_content:not(.full) .inside_scroll {
    max-width: calc(100vw - 160px);
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .main_layout.page_scroll .left_content:not(.full) .inside_scroll {
    max-width: calc(100vw - 450px);
  }
}
@media screen and (min-width: 992px) {
  .main_layout.page_scroll .left_content:not(.full) .inside_scroll {
    max-width: calc(100vw - 490px);
  }
}
.main_layout.page_scroll .left_content .inside_scroll {
  flex-grow: 1;
}
.main_layout.page_scroll .left_content > .card {
  max-height: calc(100vh - 169.34px);
  overflow-y: auto;
}
@media screen and (max-width: 575px) {
  .main_layout.page_scroll .left_content > .card {
    max-height: calc(100vh - 149.34px);
  }
}
@media screen and (min-width: 768px) {
  .main_layout.page_scroll .right_content {
    position: fixed;
    right: 18px;
  }
}
.main_layout .right_content {
  height: calc(100vh - 169.34px);
  top: 140px;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 575px) {
  .main_layout .right_content {
    height: calc(100vh - 149.34px);
  }
}
@media screen and (max-width: 575px) {
  .main_layout .right_content {
    top: 120px;
  }
}
.main_layout .right_content .main_buttons {
  padding: 0 1rem;
}
.main_layout .right_content .inside_scroll {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  height: calc(100vh - 206px);
}
@media screen and (max-width: 575px) {
  .main_layout .right_content .inside_scroll {
    height: calc(100vh - 170px);
  }
}
.main_layout .right_content .inside_scroll.has_btns {
  margin-bottom: 15px;
  height: calc(100vh - 257.53px);
}
@media screen and (max-width: 575px) {
  .main_layout .right_content .inside_scroll.has_btns {
    height: calc(100vh - 221.53px);
  }
}
.main_layout .right_content .inside_scroll.has_head {
  height: calc(100vh - 248.5px);
}
@media screen and (max-width: 575px) {
  .main_layout .right_content .inside_scroll.has_head {
    height: calc(100vh - 212.5px);
  }
}
.main_layout .right_content .inside_scroll.has_head.has_tab {
  height: calc(100vh - 306.5px);
}
@media screen and (max-width: 575px) {
  .main_layout .right_content .inside_scroll.has_head.has_tab {
    height: calc(100vh - 270.5px);
  }
}
.main_layout .right_content .inside_scroll.has_head.has_btns {
  height: calc(100vh - 285.03px);
}
@media screen and (max-width: 575px) {
  .main_layout .right_content .inside_scroll.has_head.has_btns {
    height: calc(100vh - 249.03px);
  }
}
.main_layout .right_content .inside_scroll.has_head.has_btns.has_tab {
  height: calc(100vh - 343.03px);
}
@media screen and (max-width: 575px) {
  .main_layout .right_content .inside_scroll.has_head.has_btns.has_tab {
    height: calc(100vh - 307.03px);
  }
}
.main_layout .right_menu {
  min-width: 320px;
  max-width: 320px;
}
@media screen and (max-width: 991px) {
  .main_layout .right_menu {
    min-width: 280px;
    max-width: 280px;
  }
}
@media screen and (max-width: 767px) {
  .main_layout .right_menu {
    position: fixed;
    min-width: 320px;
    max-width: 320px;
    right: -350px;
    opacity: 0;
    visibility: hidden;
    transform: translateX(350px);
    transition: all 0.5s ease-in-out;
  }
  .main_layout .right_menu.open_menu {
    transform: translateX(0);
    right: 18px;
    opacity: 1;
    visibility: visible;
  }
  .main_layout .right_menu .card {
    border-left: 1px solid #efefef;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  }
}
@media screen and (max-width: 375px) {
  .main_layout .right_menu {
    min-width: 290px;
    max-width: 290px;
  }
}

.sticky-header .right_content {
  height: calc(100vh - 123.34px);
  top: 94px;
}
@media screen and (max-width: 575px) {
  .sticky-header .right_content {
    height: calc(100vh - 103.34px);
  }
}
@media screen and (max-width: 575px) {
  .sticky-header .right_content {
    top: 74px;
  }
}

.details_header {
  height: 38px;
  background: #ffffff;
  display: flex;
  align-items: center;
  gap: 15px;
}
@media screen and (max-width: 1199px) {
  .details_header {
    gap: 8px;
  }
}
.details_header.nav {
  margin-bottom: 20px;
}
.details_header.nav .nav-item {
  height: 36px;
}
.details_header.nav .nav-item .nav-link {
  padding: 5px 0;
  height: 100%;
  line-height: 26px;
  font-size: 14px;
  cursor: pointer;
}
.details_header.nav .nav-item .nav-link:hover:not(.active) {
  color: var(--bs-gray-700);
}
.details_header.nav .nav-item .nav-link:hover:not(.active):after {
  background: var(--bs-gray-500);
  transform: scale(1);
}
.details_header.nav .nav-item:first-child {
  margin-left: 0;
}
.details_header.nav .nav-item:last-child {
  margin-left: 0;
}

.main_content_tab .nav-tabs .nav-link {
  border: none;
  border-bottom: 1px solid #efefef;
}

.image_icon {
  max-width: 60px;
  max-height: 40px;
  display: flex;
}
.image_icon svg {
  width: 100%;
  height: auto;
}
.image_icon img {
  width: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 40px;
}

.right_item .image_icon.ball_shape {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.right_item .image_icon.ball_shape img {
  width: 100%;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  mix-blend-mode: darken;
}
.right_item .image_icon.ball_shape svg {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.right_item .image_icon.ball_shape:not(.ball_shadow) img {
  border: 2px solid rgba(73, 72, 71, 0.2);
}
.right_item.active .image_icon.ball_shape:not(.ball_shadow) img {
  border-color: rgba(var(--theme-blue-rgb), 0.75);
}

.right_list .right_item {
  display: flex;
  align-items: center;
  color: var(--dark-text);
  position: relative;
  padding: 8px 14px;
  cursor: pointer !important;
}
.right_list .right_item.opacity-50 {
  cursor: auto !important;
  color: var(--dark-text) !important;
}
.right_list .right_item > span:first-child {
  display: flex;
}
.right_list .right_item .image_icon:not(.ball_shape) {
  max-width: 60px;
  max-height: 40px;
  display: flex;
  min-width: -moz-fit-content;
  min-width: fit-content;
  justify-content: flex-start;
}
.right_list .right_item .image_icon:not(.ball_shape) svg {
  width: 100%;
  height: auto;
  max-width: 60px;
}
.right_list .right_item .image_icon:not(.ball_shape) img {
  width: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 60px;
}
.right_list .right_item.active {
  color: var(--theme-blue);
  background: rgba(var(--theme-blue-rgb), 0.08);
}
.right_list .right_item.active .small_svg_set svg:not(.fill) {
  fill: none;
}
.right_list .right_item.active .small_svg_set svg.fill * {
  fill: var(--theme-blue);
}
.right_list .right_item.active .small_svg_set svg .stroke {
  stroke: var(--theme-blue);
}
.right_list .right_item:hover {
  color: var(--theme-blue);
}
.right_list .right_item .right_title {
  display: grid;
}
.right_list .right_item .right_title span {
  line-height: 1.4;
}
.right_list .action_btns {
  display: none;
  gap: 5px;
  right: 10px;
}
.right_list .action_btns a:hover svg {
  color: var(--theme-blue) !important;
}
.right_list .right_item_wrap:hover .right_item {
  color: var(--theme-blue);
  background: #f9f9f9;
}
.right_list .right_item_wrap:hover .action_btns {
  display: flex;
}
.right_list .right_item_wrap:hover .action_btns svg {
  font-size: 14px;
}
.right_list .right_item_wrap:hover.has_delete .right_item, .right_list .right_item_wrap:hover.has_copy .right_item {
  padding-right: 34px;
}
.right_list .right_item_wrap:hover.has_delete.has_copy .right_item {
  padding-right: 55px;
}
.right_list .right_item_wrap.threejs_ball .right_item {
  padding: 5px 16px;
}
.right_list .right_item_wrap.threejs_ball .right_item canvas {
  width: 40px !important;
  height: 40px !important;
  transition: all 0.3s ease-in-out;
  mix-blend-mode: darken;
}
.right_list .right_item_wrap.threejs_ball .right_item.active canvas {
  width: 70px !important;
  height: 70px !important;
}

.ball_shadow {
  position: relative;
}
.ball_shadow:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 12px;
  bottom: -8px;
  left: 0px;
  background: radial-gradient(farthest-side, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.5;
}

.color_circle {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
}
.color_circle.dot_lg {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}
.color_circle.dot_sm {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}
.color_circle.dot_md {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
}

.sq_no_ref {
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sq_no_ref span {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-top: 1px;
}
.sq_no_ref.type_2 {
  width: 40px;
  height: 40px;
  border-radius: 0;
  background-image: url("../img/icons/hexagon.png");
  background-size: contain;
}
.sq_no_ref.type_2 span {
  color: #000000;
  font-size: 16px;
}

.form-switch .form-check-input {
  border: none !important;
  background-color: #D6D9DD;
  background-image: url("../img/check_dot.svg") !important;
}
.form-switch .form-check-input:checked {
  background-color: var(--theme-blue);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table tr:hover td {
  background: var(--readonly-color);
}
.table tr:hover.bg_trans td {
  background: transparent !important;
}
.table tr th {
  text-align: left;
  font-size: 0.8125rem;
  border: none;
}
.table tr td {
  text-align: left;
  font-size: 0.8125rem;
  border: none;
}
.table.no_td_bg tr td {
  background: transparent !important;
}
.table.no_border tr td {
  border: none !important;
}
.table.quote_list tr td,
.table.quote_list tr th {
  min-width: 120px;
}
.table.discount_table tr th:last-child {
  width: 140px;
  text-align: right;
}
.table.discount_table tr th {
  padding: 6px 8px;
}
.table.discount_table tr td {
  padding: 6px 8px;
}
.table.discount_table tr td:first-child {
  min-width: 200px;
}
.table.discount_table tr td input {
  text-align: right;
  padding: 4.25px;
  border: none !important;
  min-width: 60px;
}
.table.discount_table tr td input::placeholder, .table.discount_table tr td input:-ms-input-placeholder, .table.discount_table tr td input::-ms-input-placeholder {
  /* Firefox, Chrome, Opera */
  text-align: right;
}
.table.discount_table tr td input:-moz-read-only {
  padding: 10px;
}
.table.discount_table tr td input:read-only {
  padding: 10px;
}

.form-group:not(.mb-0) {
  margin-bottom: 1.5rem !important;
}

.MuiOutlinedInput-root {
  border-radius: 5px !important;
}

.MuiInputBase-root .MuiSelect-select {
  padding: 0.37rem 1.75rem 0.37rem 0.75rem;
  min-height: 36.53px;
  box-sizing: border-box;
  line-height: 1.5;
  font-size: 15px;
  color: var(--dark-text);
  background: transparent !important;
  white-space: initial;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 24px;
}
.MuiInputBase-root .MuiSelect-select ~ fieldset {
  height: 36.53px;
  top: 0;
}
.MuiInputBase-root .MuiSelect-select ~ .MuiSelect-icon {
  top: calc(50% - 10px);
}
.MuiInputBase-root .MuiSelect-select .select_with_img .img_wrap {
  display: none;
}
.MuiInputBase-root .PrivateNotchedOutline-root-1 {
  top: 0;
}
.MuiInputBase-root .MuiSvgIcon-root {
  color: var(--theme-black);
  width: 20px;
  height: 20px;
}

.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: var(--bs-input-border-color) !important;
}

.MuiSelect-root[aria-expanded=true] ~ .MuiSvgIcon-root {
  transform: rotate(180deg);
  color: var(--theme-blue);
}
.MuiSelect-root[aria-expanded=true] ~ .MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-blue) !important;
  border-width: 1px !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  max-width: calc(100vw - 150px);
  border: 1px solid var(--bs-border-color);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .MuiMenu-list > li {
  font-size: 14px;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .MuiMenu-list > li .MuiTypography-body1 {
  font-size: 14px;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .MuiMenu-list > li .select_with_img .img_wrap {
  display: flex;
  justify-content: center;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .MuiMenu-list > li .select_with_img .img_wrap.size_lg {
  width: 60px;
  max-width: 60px;
  max-height: 60px;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .MuiMenu-list > li .select_with_img .img_wrap img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.modal_select_show {
  display: none;
}

.modal_select {
  max-width: 100%;
}
.modal_select span {
  line-height: unset !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: calc(100% - 15px);
}
.modal_select .modal_select_hide {
  display: none;
}
.modal_select .modal_select_show {
  display: flex;
}
.modal_select .small_svg_set ~ .d-flex.flex-column {
  width: 100%;
}
.modal_select .MuiInputBase-root {
  max-width: 100%;
}
.modal_select .dropdown_with_icon {
  max-width: 220px;
  min-width: 100%;
}

.small_svg_set {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60px;
  max-height: 40px;
}
.small_svg_set svg:not(.fill) {
  fill: none;
}
.small_svg_set svg .stroke {
  stroke: rgb(73, 72, 71);
}
.small_svg_set svg {
  width: 100%;
  height: auto;
}
.small_svg_set img {
  width: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 40px;
}

.MuiListItem-root.Mui-selected {
  background-color: #f6f6f6 !important;
}
.MuiListItem-root.Mui-selected .small_svg_set svg:not(.fill) {
  fill: none;
}
.MuiListItem-root.Mui-selected .small_svg_set svg .stroke {
  stroke: var(--theme-blue);
}

.MuiListItem-root .dropdown_with_icon {
  max-width: "300px";
}
.MuiListItem-root .dropdown_with_icon span:last-child {
  white-space: normal;
  line-height: 1.2 !important;
}

.MuiCheckbox-root {
  padding: 0 !important;
  margin-right: 10px !important;
  color: var(--bs-input-border-color) !important;
}
.MuiCheckbox-root.Mui-checked {
  color: var(--theme-blue) !important;
}

.MuiList-root.MuiMenu-list {
  padding: 0;
}
.MuiList-root.MuiMenu-list > li {
  padding: 6px 8px !important;
}

.page_setup_logo {
  max-width: 200px;
  max-height: 100px;
  display: flex;
}
.page_setup_logo.square {
  max-width: 100px;
  max-height: 100px;
}
.page_setup_logo img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: left;
     object-position: left;
}

.mini_text {
  width: 70px;
}

.min-w-120 {
  min-width: 120px;
}

.min-w-150 {
  min-width: 150px;
}

.min-w-180 {
  min-width: 180px;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-180 {
  max-width: 180px;
}

.max-w-200 {
  max-width: 200px;
}

.max-w-388 {
  max-width: 388px;
}

.max-w-400 {
  max-width: 400px;
}

.file_choose input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
}

.head_item.wrap_address {
  max-width: 320px;
  white-space: break-spaces;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: var(--theme-blue) !important;
}

.MuiFormControl-root .MuiIconButton-root {
  margin-right: 5px !important;
}
.MuiFormControl-root .MuiFormControlLabel-root {
  margin-left: 0 !important;
}
.MuiFormControl-root .MuiFormControlLabel-root .MuiRadio-root {
  padding: 0 !important;
}
.MuiFormControl-root .MuiFormGroup-root .MuiFormControlLabel-root {
  margin-right: 0 !important;
}
.MuiFormControl-root .MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}
.MuiFormControl-root .MuiTypography-body1 {
  font-size: var(--bs-body-font-size);
}

.page_type_radio_2 {
  position: relative;
  margin-bottom: 0 !important;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 3px;
}
.page_type_radio_2.max_w_100 {
  max-width: 100px;
}
.page_type_radio_2 .MuiRadio-root {
  display: none !important;
}
.page_type_radio_2 .MuiTypography-root {
  width: 100%;
  height: 100%;
}
.page_type_radio_2 .MuiTypography-root > div {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}
.page_type_radio_2 .MuiTypography-root > div svg {
  width: 28px;
  height: 28px;
  margin-bottom: 5px;
}
.page_type_radio_2 .MuiTypography-root > div svg:not(.no_svg) path,
.page_type_radio_2 .MuiTypography-root > div svg:not(.no_svg) rect {
  stroke: var(--bs-body-color);
}
.page_type_radio_2 .MuiTypography-root > div.landscape svg {
  transform: rotate(90deg) scaleX(-1);
}
.page_type_radio_2 .MuiTypography-root > div span:last-child {
  word-break: break-word;
  line-height: 1.2;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  font-size: 13px;
  color: var(--theme-black);
}
.page_type_radio_2 .Mui-checked ~ .MuiTypography-root > div {
  background: transparent;
  border-color: var(--theme-blue);
}
.page_type_radio_2 .Mui-checked ~ .MuiTypography-root > div span {
  color: var(--theme-blue) !important;
}
.page_type_radio_2 .Mui-checked ~ .MuiTypography-root > div span svg:not(.no_svg) path,
.page_type_radio_2 .Mui-checked ~ .MuiTypography-root > div span svg:not(.no_svg) rect {
  stroke: var(--theme-blue);
}
.page_type_radio_2 .MuiIconButton-root {
  position: absolute !important;
  top: 4px;
  right: 2px;
}

.input_with_buttons {
  position: relative;
}
.input_with_buttons input {
  padding-right: 30px;
}
.input_with_buttons .up_down_btns {
  display: flex;
  flex-direction: column;
  width: 20px;
  position: absolute;
  right: 4px;
}
.input_with_buttons .up_down_btns span {
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.input_with_buttons .up_down_btns span.disabled {
  cursor: auto;
}
.input_with_buttons .up_down_btns span svg {
  transform: scale(1.1);
  color: var(--theme-black);
}
.input_with_buttons .up_down_btns span:hover svg {
  color: var(--dark-text);
}

.error_pages {
  min-height: calc(100vh - 94px - 18px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4%;
  background: #ffffff;
}
.error_pages.full_cover {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.error_pages .banner_img {
  display: flex;
  justify-content: center;
  max-height: calc(50vh - 215px);
  min-height: 200px;
}
.error_pages .banner_img.maintenance_img {
  min-height: 350px;
}
.error_pages .banner_img.maintenance_img.processing_img {
  min-height: 80px;
  margin: auto;
  max-width: 400px;
}
.error_pages .banner_img.maintenance_img.processing_img img {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}
.error_pages .banner_img img {
  width: 100%;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.error_pages .logo_large {
  width: 100%;
  max-width: 200px;
  height: unset;
  max-height: unset;
}
.error_pages .bx-spin {
  animation: spin 2s linear infinite;
}
.error_pages h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(3.5rem + 3vw);
}
.error_pages h4 {
  font-size: 24px !important;
  font-weight: 500 !important;
}
.error_pages h4.display-4 {
  font-size: calc(1.5rem + 2vw);
}
.error_pages h4 ~ p {
  font-size: 16px;
}

div[data-testid=oval-loading] svg {
  stroke: var(--theme-blue);
}
div[data-testid=oval-loading] svg circle {
  stroke: var(--theme-blue);
}

.loader_main.full_loader {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
  cursor: progress;
}
.loader_main.save_images {
  background: #ffffff;
  z-index: 997;
}

.Toastify__toast-container--top-right {
  z-index: 1045 !important;
  top: 80px !important;
}
@media screen and (max-width: 575px) {
  .Toastify__toast-container--top-right {
    top: 60px !important;
  }
}

.inside_content {
  background: transparent !important;
}

.form-check:not(.form-switch) {
  margin-bottom: 8px;
  padding-left: 1.5rem;
}
.form-check:not(.form-switch) .form-check-input {
  background-color: transparent !important;
  border-width: 1px !important;
  border-color: var(--text-muted);
  width: 18px;
  height: 18px;
  margin-left: -1.5rem;
  margin-top: 1px;
}
.form-check:not(.form-switch) .form-check-input:checked[type=checkbox] {
  background-color: var(--theme-blue) !important;
  border-color: var(--theme-blue) !important;
}
.form-check:not(.form-switch) .form-check-input.size_sm {
  width: 16px;
  height: 16px;
}
.form-check:not(.form-switch).type_2 .form-check-input:checked[type=checkbox] {
  background-color: transparent !important;
}
.form-check:not(.form-switch).type_2 .form-check-input:checked[type=checkbox]:before {
  content: "✓";
  color: var(--theme-blue) !important;
  width: 18px;
  height: 18px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-check:not(.form-switch) label {
  font-weight: 400;
}

.three_element canvas {
  max-width: calc(100vw - 155px);
  max-height: calc(100vh - 230px);
}

.remove_item_wrap > div {
  margin-bottom: 1rem;
}

.remove_item {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.remove_item span {
  font-size: 13px;
}
.remove_item span svg {
  width: 20px;
  height: 20px;
  color: var(--theme-blue);
}
.remove_item span:first-child {
  cursor: pointer;
}
.remove_item span:last-child strong {
  font-weight: 500 !important;
  margin-right: 6px;
}
.remove_item > a {
  padding: 0;
  margin-right: 8px;
  border: 1px solid var(--theme-blue);
  border-radius: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.remove_item > a.disabled {
  border-color: var(--text-muted);
}

input:disabled,
select:disabled {
  cursor: auto !important;
  pointer-events: none;
}

.Mui-disabled {
  background: var(--readonly-color);
  cursor: auto !important;
  pointer-events: none;
}

.not_allowed {
  pointer-events: none;
}
.not_allowed .form-control,
.not_allowed select {
  cursor: auto !important;
  pointer-events: none;
  background: var(--readonly-color);
}
.not_allowed .form-check-input {
  opacity: 0.8 !important;
  cursor: auto !important;
  pointer-events: none;
}
.not_allowed .custom_dropzone,
.not_allowed label[for=uploadFile] {
  display: none;
}
.not_allowed .MuiSelect-root {
  background: var(--readonly-color) !important;
  cursor: auto !important;
  pointer-events: none;
}

.inside_scroll .rangeslider-horizontal {
  height: 6px;
  border-radius: 10px;
}
.inside_scroll .rangeslider-horizontal.w-75 {
  width: 80% !important;
  margin: 0;
}
.inside_scroll .rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: var(--theme-blue);
  border-radius: 10px;
  top: 0;
  box-shadow: none;
}
.inside_scroll .rangeslider-horizontal .rangeslider__handle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.inside_scroll .rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  top: -4px;
  left: -2px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: unset;
  border: 2px solid #ADADAD;
}
.inside_scroll .rangeslider {
  box-shadow: unset;
}

.pointer_none {
  pointer-events: none;
}

.MuiTooltip-tooltipArrow {
  background: #ffffff !important;
  color: var(--dark-text) !important;
  font-size: 11px !important;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
}

.MuiTooltip-arrow {
  position: relative;
}
.MuiTooltip-arrow::before {
  color: #ffffff !important;
  z-index: 2;
  font-size: 6px;
  right: 0px !important;
}
.MuiTooltip-arrow::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}

.MuiTooltip-tooltipPlacementLeft .MuiTooltip-arrow::after,
.MuiTooltip-tooltipPlacementRight .MuiTooltip-arrow::after {
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid rgba(50, 50, 105, 0.15);
  top: -1px;
  right: 0.4px !important;
}

.MuiTooltip-tooltipPlacementLeft .MuiTooltip-arrow::after {
  border-right: none;
  border-left: 7px solid rgba(50, 50, 105, 0.15);
  right: unset;
  left: 0.4px !important;
}

.MuiTooltip-tooltipPlacementBottom .MuiTooltip-arrow::after,
.MuiTooltip-tooltipPlacementTop .MuiTooltip-arrow::after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid rgba(50, 50, 105, 0.15);
  top: 0.8px;
  left: -1px !important;
}

.MuiTooltip-tooltipPlacementBottom .MuiTooltip-arrow::after {
  border-top: none;
  border-bottom: 7px solid rgba(50, 50, 105, 0.15);
  top: unset;
  bottom: 0.8px;
}

.MuiTooltip-tooltipPlacementRight {
  right: -10px;
}

.dual_box_design {
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 992px) {
  .dual_box_design {
    flex-direction: row;
  }
}

.dual_box_wrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: calc(100vw - 116px);
}
@media screen and (min-width: 992px) {
  .dual_box_wrap {
    width: calc(50% - 0.5rem);
    max-width: 400px;
  }
}
.dual_box_wrap .dual_box {
  border-radius: 5px;
  border: 1px solid #CCC;
  background: #FFF;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.dual_box_wrap .dual_box .dual_box_list {
  flex-grow: 1;
  min-height: 254px;
  max-height: 254px;
  overflow-y: auto;
}
.dual_box_wrap .dual_box.has_btns .dual_box_list {
  min-height: 201.47px;
  max-height: 201.47px;
}
.dual_box_wrap .dual_box .list_item {
  display: flex;
  align-items: center;
  color: var(--dark-text);
  position: relative;
  padding: 6px 40px 6px 16px;
}
.dual_box_wrap .dual_box .list_item > canvas {
  width: 40px !important;
  height: 40px !important;
}
.dual_box_wrap .dual_box .list_item .ball_shape {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.dual_box_wrap .dual_box .list_item .ball_shape img {
  -o-object-fit: cover;
     object-fit: cover;
  max-height: unset;
  max-width: 100%;
  mix-blend-mode: darken;
}
.dual_box_wrap .dual_box .list_item .ball_shape svg {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.dual_box_wrap .dual_box .list_item .ball_shape:not(.ball_shadow) img {
  border: 2px solid rgba(73, 72, 71, 0.2);
}
.dual_box_wrap .dual_box .list_item.active {
  color: var(--theme-blue);
  background: rgba(var(--theme-blue-rgb), 0.08);
}
.dual_box_wrap .dual_box .list_item.active .ball_shape:not(.ball_shadow) img {
  border-color: rgba(var(--theme-blue-rgb), 0.75);
}

@media screen and (max-width: 575px) {
  .palette-color-picker .photoshop-picker {
    max-width: 320px;
  }
}
@media screen and (min-width: 992px) {
  .palette-color-picker .photoshop-picker {
    max-width: unset;
  }
}
.palette-color-picker .photoshop-picker > .flexbox-fix {
  padding: 0 !important;
  gap: 10px;
}
@media screen and (max-width: 575px) {
  .palette-color-picker .photoshop-picker > .flexbox-fix {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(1) {
  max-width: 100%;
  border: none !important;
  width: 200px;
  height: 200px;
  max-width: 200px;
  max-height: 200px;
  overflow: unset !important;
}
@media screen and (min-width: 992px) {
  .palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(1) {
    width: 250px;
    height: 250px;
    max-width: 250px;
    max-height: 250px;
  }
}
.palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(2) {
  width: 12px !important;
  height: 200px !important;
  border: none !important;
  border-radius: 12px !important;
  overflow: hidden;
  margin-left: 0 !important;
}
@media screen and (max-width: 575px) {
  .palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(2) {
    position: absolute !important;
    left: 225px;
  }
}
@media screen and (min-width: 992px) {
  .palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(2) {
    height: 250px !important;
  }
}
.palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) {
  max-width: 95px !important;
}
@media screen and (max-width: 575px) {
  .palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) {
    margin-left: 20px !important;
    height: 125px;
  }
}
.palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) .flexbox-fix {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) .flexbox-fix > div:nth-child(1) {
  display: none;
}
.palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) .flexbox-fix > div:nth-child(2) {
  margin-left: 0 !important;
}
.palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) .flexbox-fix > div:nth-child(2) > div:nth-child(1), .palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) .flexbox-fix > div:nth-child(2) > div:nth-child(2) {
  display: none;
}
.palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) .flexbox-fix > div:nth-child(2) > div:nth-child(3) > div:nth-child(1), .palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) .flexbox-fix > div:nth-child(2) > div:nth-child(3) > div:nth-child(2), .palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) .flexbox-fix > div:nth-child(2) > div:nth-child(3) > div:nth-child(3), .palette-color-picker .photoshop-picker > .flexbox-fix > div:nth-child(3) .flexbox-fix > div:nth-child(2) > div:nth-child(3) > div:last-child {
  display: none;
}

.custom_dropzone {
  height: 100px;
  border-radius: 5px;
  border: 2px dashed var(--bs-input-border-color);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.custom_dropzone p {
  margin-bottom: 0 !important;
  color: #D2D2D2;
  font-size: 16px;
  text-align: center;
}

.dimention_image {
  max-width: 200px;
  max-height: 100px;
  display: flex;
  margin-bottom: 1rem;
}
.dimention_image svg {
  width: 100%;
  height: auto;
}
.dimention_image img {
  width: auto;
  max-width: 100%;
  max-height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}

.input_with_img {
  margin-bottom: 1.5rem;
}
.input_with_img .form_img {
  max-width: 42px;
  max-height: 42px;
  display: flex;
}
.input_with_img .form_img svg {
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  width: auto;
  height: auto;
}
.input_with_img .input_with_img_item {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  flex-wrap: nowrap;
}
.input_with_img .form-control {
  margin-bottom: 0 !important;
  max-width: 80px;
  min-width: 80px;
  padding-right: 20px;
  padding-left: 8px;
}

.modal .right_menu_items {
  max-width: 420px;
}
.modal .right_menu_items .inside_scroll {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.designer_wrap {
  width: 100%;
  overflow: hidden;
}
.designer_wrap canvas {
  max-width: calc(100vw - 155px);
  max-height: calc(100vh - 230px);
}
.designer_wrap.has_header canvas {
  max-width: calc(100vw - 155px);
  max-height: calc(100vh - 300px);
}
.designer_wrap .designer-right-menu {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(10px) saturate(2);
          backdrop-filter: blur(10px) saturate(2);
  width: 300px;
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 991px) {
  .designer_wrap .designer-right-menu {
    width: 0;
    transform: translateX(350px);
    opacity: 0;
    visibility: hidden;
  }
}
.designer_wrap .designer-right-menu .right_list .right_item_wrap .right_item {
  padding: 7px 2px;
}
.designer_wrap .designer-right-menu .right_list .right_item_wrap .right_item .image_icon {
  max-height: 35px;
}
.designer_wrap .designer-right-menu .right_list .right_item_wrap .right_item .image_icon img,
.designer_wrap .designer-right-menu .right_list .right_item_wrap .right_item .image_icon svg {
  max-height: 35px;
}
.designer_wrap .designer-right-menu .right_list .right_item_wrap:hover .right_item {
  background: transparent;
}
.designer_wrap .designer-right-menu.main_design > div:first-child {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 6px;
}
.designer_wrap .designer-right-menu.main_design > div:first-child .tab-content {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
  margin-right: -6px;
  padding-right: 6px;
}
.designer_wrap .designer-right-menu.main_design > div:first-child.accordion {
  max-height: calc(100vh - 200px);
}
.designer_wrap .designer-right-menu.main_design > div:first-child.accordion .sill_list_scroll {
  max-height: calc(100vh - 435px);
  overflow-y: auto;
  margin-bottom: 15px;
  margin-right: -6px;
  padding-right: 6px;
}
.designer_wrap .designer-right-menu.main_design > div:first-child.accordion .panel_object_list {
  max-height: calc(100vh - 500px);
  overflow-y: auto;
}
.designer_wrap .designer-right-menu.main_design > div:first-child.accordion .tab-content {
  margin-right: -6px;
  padding-right: 6px;
}
.designer_wrap .designer-right-menu.main_design > div:first-child.accordion .tab-content .panel_object_list {
  max-height: calc(100vh - 490px);
  overflow-y: auto;
}
.designer_wrap .designer-right-menu.main_design > div:first-child + .accordion .details_header + .tab-content {
  max-height: calc(100vh - 540px);
  overflow-y: auto;
}
.designer_wrap .designer-right-menu.panel_design > div:first-child {
  overflow-y: hidden;
}
.designer_wrap .designer-right-menu.panel_design > div:first-child .accordion-body {
  padding-right: 10px;
}
.designer_wrap .designer-right-menu.panel_design > div:first-child .accordion-body .inside_scroll {
  max-height: calc(100vh - 360px);
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 6px;
}
.designer_wrap .designer-right-menu .scroll_fix_designer.inside_scroll {
  max-height: calc(100vh - 360px);
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 6px;
}
.designer_wrap .designer-right-menu.open_menu {
  width: 300px;
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.designer_wrap .designer-right-menu .accordion {
  --bs-accordion-border-color: transparent;
}
.designer_wrap .designer-right-menu .accordion .accordion-item .accordion-button {
  padding: 5px 0;
  font-size: 17px;
  min-height: 40px;
}
.designer_wrap .designer-right-menu .accordion .accordion-item .accordion-button::after {
  margin-left: 10px !important;
}
.designer_wrap .designer-right-menu .accordion .accordion-item .accordion-button:not(.collapsed) {
  color: var(--theme-blue);
  background-color: transparent;
}
.designer_wrap .designer-right-menu .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}
.designer_wrap .designer-right-menu .accordion .accordion-item.hardware_acc .accordion-header {
  overflow: hidden;
  max-width: 100%;
}
.designer_wrap .designer-right-menu .accordion .accordion-item.hardware_acc .accordion-button.collapsed .ms-auto.dropdown {
  display: none;
}
.designer_wrap .designer-right-menu .accordion .accordion-body {
  padding: 5px 0;
}
.designer_wrap .designer-right-menu .accordion .accordion-body .panel_object_list .right_item {
  padding: 5px 0;
  background: transparent !important;
  cursor: pointer;
}
.designer_wrap .designer-right-menu .accordion .accordion-body .panel_object_list .right_item > span:first-child {
  display: flex;
}
.designer_wrap .designer-right-menu .accordion .accordion-body .panel_object_list .right_item .right_title span:first-child {
  font-weight: 500;
  font-size: 14px;
}
.designer_wrap .designer-right-menu .accordion .accordion-body .panel_object_list .right_item .right_title span:last-child {
  font-size: 13px !important;
}
.designer_wrap .designer-right-menu .accordion .accordion-body .panel_object_list .right_item:hover .right_title span:first-child {
  color: var(--theme-blue) !important;
}
.designer_wrap .designer-right-menu .accordion .accordion-body .panel_object_list .right_item .threejs_ball canvas {
  width: 40px !important;
  height: 40px !important;
  mix-blend-mode: darken;
}
.designer_wrap .designer-right-menu .accordion .details_header {
  background: transparent !important;
}
.designer_wrap .designer-right-menu .accordion .details_header .nav-item .nav-link.active {
  background: transparent !important;
}
.designer_wrap .designer-right-menu .details_header,
.designer_wrap .designer-right-menu .form-control {
  background: transparent !important;
}
.designer_wrap .designer-right-menu .nav .nav-item .nav-link {
  background-color: transparent !important;
}
.designer_wrap .designer-right-menu .tab-content .tab-pane .MuiFormControl-root {
  width: 100%;
}
.designer_wrap.new_design {
  position: relative;
  overflow: visible;
  padding-left: 16px;
}
.designer_wrap.new_design .designer_header {
  position: absolute;
  background: transparent;
  top: 0;
  margin-left: 16px;
  left: 0;
  z-index: 1;
}
.designer_wrap.new_design .designer_btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
.designer_wrap.new_design .canvas_wrap #container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.designer_wrap.new_design .canvas_wrap #container canvas {
  max-width: unset;
  max-height: unset;
}
.designer_wrap.new_design .designer-right-menu {
  position: fixed;
  top: 140px;
  right: 18px;
}
.designer_wrap.new_design .design_footer {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}

.label_radio {
  margin: 0 !important;
}
.label_radio .MuiIconButton-root {
  display: none;
}
.label_radio .MuiTypography-root > div {
  border: 1px solid var(--theme-blue);
  border-radius: 4px;
  margin: 0;
  padding: 5px 15px;
}
.label_radio .MuiTypography-root > div span {
  font-size: 14px;
}
.label_radio .Mui-checked ~ .MuiTypography-root > div {
  background: var(--theme-blue);
  border-color: var(--theme-blue);
}
.label_radio .Mui-checked ~ .MuiTypography-root > div span {
  color: #ffffff !important;
}
.label_radio.active {
  background-color: var(--theme-blue);
  color: #ffffff;
}
.label_radio.frame_type {
  width: 100%;
}

.nested_checkbox .react-checkbox-tree ol,
.nested_checkbox .react-checkbox-tree li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nested_checkbox .react-checkbox-tree ol ol {
  margin-left: 20px;
}
.nested_checkbox .rct-node .rct-text {
  margin: 8px 0;
  position: relative;
  padding-right: 40px;
  display: flex;
  align-items: center;
}
.nested_checkbox .rct-node .rct-text input[type=checkbox] {
  background-color: transparent !important;
  border-width: 1px !important;
  border-color: var(--text-muted);
  width: 16px;
  height: 16px;
  margin-left: -1.5rem;
  margin-top: 1px;
}
.nested_checkbox .rct-node .rct-text input[type=checkbox]:checked[type=checkbox] {
  background-color: var(--theme-blue) !important;
  border-color: var(--theme-blue) !important;
}
.nested_checkbox .rct-node label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.nested_checkbox .rct-node label input[type=checkbox] {
  margin-right: 10px;
  width: 14px;
  height: 14px;
}
.nested_checkbox .rct-node-parent button.rct-collapse {
  position: absolute;
  right: 0;
  border: none;
  background: transparent;
  color: var(--bs-body-color);
}
.nested_checkbox .rct-node-parent.rct-node-expanded > .rct-text > button.rct-collapse {
  color: var(--theme-blue);
}
.nested_checkbox .rct-node-parent.rct-node-expanded > .rct-text > label {
  color: var(--theme-blue);
}

.tab-page .table-responsive {
  max-width: 100%;
}

.seperator {
  border-top: 1px solid var(--bs-border-color);
}

.fixed_m_scroll {
  height: calc(100vh - 165px);
  overflow-y: auto;
}
.fixed_m_scroll:not(.add_frame_2) {
  padding-right: 16px;
}
.fixed_m_scroll.no_head {
  height: calc(100vh - 145px);
}
.fixed_m_scroll.full_h {
  height: calc(100vh - 120px);
}
.fixed_m_scroll.add_frame_2 {
  height: calc(100vh - 135px);
  overflow-y: auto;
  margin-bottom: 20px;
}
.fixed_m_scroll.add_frame_2 .panel_item {
  font-size: 12px;
}
.fixed_m_scroll.add_frame_2 .panel_item .img_wrap {
  min-width: unset;
  width: auto;
  height: 40px;
  justify-content: start;
}

.row.custom_row {
  margin-left: -5px;
  margin-right: -5px;
}
.row.custom_row > div {
  padding-left: 5px;
  padding-right: 5px;
}
.row.custom_row_8 {
  margin-left: -8px;
  margin-right: -8px;
}
.row.custom_row_8 > div {
  padding-left: 8px;
  padding-right: 8px;
}
.row.custom_row_pdf {
  margin-left: -3px;
  margin-right: -3px;
}
.row.custom_row_pdf > div {
  padding-left: 3px;
  padding-right: 3px;
}

.search_suggestion {
  position: relative;
  width: 100%;
}
.search_suggestion .form-control {
  padding-right: 25px !important;
}
.search_suggestion .toggle_icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  transform: rotateZ(0deg);
  transition: transform 0.1s ease-in-out;
}
.search_suggestion .toggle_icon.active {
  transform: rotateZ(180deg);
}
.search_suggestion .form-control.input_select {
  cursor: pointer;
  background: transparent !important;
  height: 36.52px;
}
.search_suggestion .form-control.input_select.disabled {
  background: var(--readonly-color) !important;
}
.search_suggestion .suggestions_wrap {
  position: absolute;
  z-index: 999;
  width: 100%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 8px;
  border: 1px solid var(--bs-border-color);
}
.search_suggestion .suggestions {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
}
.search_suggestion .suggestions li {
  cursor: pointer !important;
  display: flex;
  align-items: center;
  color: #000000;
}
.search_suggestion .suggestions li.active {
  color: var(--theme-blue);
}
.search_suggestion .suggestions li:hover {
  color: var(--theme-blue);
}
.search_suggestion .suggestions li.select-opt .select-item {
  padding: 5px 10px !important;
  min-height: 32px;
}
.search_suggestion .suggestions li.select-opt .select-item.form-label {
  cursor: pointer;
}
.search_suggestion .suggestions li.select-opt .select-item.form-label .form-check-input {
  margin-left: 0;
}
.search_suggestion .suggestions li.select-opt .select-item h6 {
  line-height: 1.5;
}
.search_suggestion .suggestions li.select-opt:hover h6, .search_suggestion .suggestions li.select-opt.active h6 {
  color: var(--bs-primary);
}
.search_suggestion .no-suggestions {
  padding: 8px;
  color: #999;
}
.search_suggestion .selected-items {
  margin-top: 10px;
}
.search_suggestion .selected-items ul {
  list-style: none;
  padding: 0;
}
.search_suggestion .selected-items ul li {
  padding: 4px;
  background-color: #f4f4f4;
}

.custom-multiselect .multiselect-container {
  color: black;
  height: auto;
  background-color: #f9f9f9;
}
.custom-multiselect .optionListContainer li {
  cursor: pointer;
  color: black;
}
.custom-multiselect .optionListContainer li:hover {
  background-color: var(--bs-blue-hover);
  color: white;
}
.custom-multiselect .chip {
  background-color: var(--theme-blue);
  color: white;
}
.custom-multiselect .highlightOption {
  background-color: var(--bs-blue-hover);
  color: white !important;
}
.custom-multiselect .search-wrapper {
  padding: 0 !important;
}
.custom-multiselect .search-wrapper .searchBox {
  width: 100%;
  padding: 5px 8px;
  border: none;
}
.custom-multiselect .dropdown {
  max-height: 200px;
  overflow-y: auto;
}
.custom-multiselect .optionContainer > li.option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
}
.custom-multiselect .optionContainer > li.option input {
  margin: 0 !important;
  width: 16px;
  height: 16px;
  border-radius: 4px;
}
.custom-multiselect .optionContainer > li.option.selected, .custom-multiselect .optionContainer > li.option:hover {
  background-color: transparent;
  color: var(--theme-blue) !important;
}
.custom-multiselect .optionContainer > li.option.selected input[type=checkbox] {
  background-color: var(--theme-blue) !important;
  border-color: var(--theme-blue) !important;
}
.custom-multiselect .optionContainer .notFound {
  color: var(--dark-text);
}

.item_box_wrap .item_box {
  border-radius: 8px;
}
.item_box_wrap .item_box .item_box_inner {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #f4f4f4;
  height: 100%;
}
.item_box_wrap .item_box .item_box_inner:hover, .item_box_wrap .item_box .item_box_inner.selected_item {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(var(--theme-blue-rgb), 0.1);
}
@media screen and (max-width: 480px) {
  .item_box_wrap .item_box .item_box_inner h5 {
    font-size: calc(0.75rem + 0.4vw);
  }
  .item_box_wrap .item_box .item_box_inner p {
    font-size: calc(0.5rem + 0.4vw);
  }
}
.item_box_wrap .item_box .item_box_inner .item_header {
  display: none;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding: 5px 6px;
}
.item_box_wrap .item_box .item_box_inner .item_header.has_checked_items {
  display: flex;
}
.item_box_wrap .item_box .item_box_inner .item_header span a {
  color: var(--text-muted);
}
.item_box_wrap .item_box .item_box_inner .item_header span a:hover:first-child {
  color: var(--bs-primary);
}
.item_box_wrap .item_box .item_box_inner .item_header span a:hover:last-child {
  color: var(--bs-danger);
}
.item_box_wrap .item_box .item_box_inner:hover .item_header {
  display: flex;
}
.item_box_wrap .item_box .item_box_inner .item_box_img {
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 40px 20px 20px;
  flex-grow: 1;
  max-height: 200px;
}
@media screen and (min-width: 376px) and (max-width: 576px) {
  .item_box_wrap .item_box .item_box_inner .item_box_img {
    padding: 40px 10px 10px;
  }
}
.item_box_wrap .item_box .item_box_inner .item_box_img img {
  width: 100%;
  max-width: 150px;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.item_box_wrap .item_box .item_box_inner .item_box_content {
  padding: 10px 10px;
  color: var(--bs-body-color);
  height: 60.5px;
}
.item_box_wrap .item_box .item_box_inner .item_box_content h5,
.item_box_wrap .item_box .item_box_inner .item_box_content p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item_box_wrap .item_box .item_box_inner.selected_item {
  border-color: rgba(var(--theme-blue-rgb), 0.3);
}
.item_box_wrap.dynamic_card .item_box {
  margin-bottom: 20px;
}
@media screen and (min-width: 376px) {
  .item_box_wrap.dynamic_card .item_box {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media screen and (min-width: 630px) {
  .item_box_wrap.dynamic_card .item_box {
    max-width: 33.33%;
  }
}
@media screen and (min-width: 921px) and (max-width: 1199px) {
  .item_box_wrap.dynamic_card .item_box {
    max-width: 25%;
  }
}
@media screen and (min-width: 1200px) {
  .item_box_wrap.dynamic_card .item_box {
    max-width: 20%;
  }
}
@media screen and (min-width: 1600px) {
  .item_box_wrap.dynamic_card .item_box {
    max-width: 14.285%;
  }
}
@media screen and (min-width: 1920px) {
  .item_box_wrap.dynamic_card .item_box {
    max-width: 220px;
  }
}
.item_box_wrap.type_2 .item_box .item_box_inner .item_box_img {
  padding: 20px;
}
.item_box_wrap.type_2 .item_box .item_box_inner .item_box_content {
  height: 39.5px;
}

.custom_panel_wrap {
  position: fixed;
  top: 0;
  z-index: 1049;
  left: 0;
}
.custom_panel_wrap > div {
  z-index: 10;
}
.custom_panel_wrap .custom_panel {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  background: #ffffff;
  width: 100%;
  z-index: 1045;
  left: unset;
  transform: translateX(-630px);
  transition: transform 0.3s ease-in-out;
}
@media screen and (min-width: 576px) {
  .custom_panel_wrap .custom_panel {
    transform: translateX(-530px);
    width: 530px;
  }
}
.custom_panel_wrap.sidebar-open .custom_panel {
  transform: translateX(0);
}
.custom_panel_wrap.sidebar-open:before {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.panel_item {
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  padding: 4px 0 4px 6px;
}
@media screen and (max-width: 575px) {
  .panel_item:not(.frame_type) > div {
    flex-direction: column;
    align-items: start !important;
  }
}
.panel_item .img_wrap {
  min-width: 40px;
  width: 40px;
  height: 40px;
  max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.panel_item .img_wrap img {
  max-width: 100%;
  max-height: 100%;
}
.panel_item .title {
  word-break: break-word;
}
.panel_item.selected {
  background: rgba(var(--theme-blue-rgb), 0.1);
}
.panel_item.selected .title,
.panel_item.selected svg {
  color: var(--theme-blue);
}
.panel_item:hover .title {
  color: var(--theme-blue);
}
.panel_item.frame_type {
  border: 1px solid var(--bs-border-color);
  padding: 4px;
  margin: 0 0 10px 0;
}
.panel_item.frame_type.selected {
  border-color: var(--theme-blue);
}

.alert {
  border-radius: 8px;
}
.alert.alert-secondary {
  border: none;
  background: #f5f5f5;
}

.MuiPopover-root {
  z-index: 1351 !important;
}

.modal-open .MuiPopover-root {
  z-index: 9999 !important;
}

.sidebar_active .MuiPopover-root {
  z-index: 1045 !important;
}

@media screen and (min-width: 576px) {
  .w-sm-50 {
    width: 50% !important;
  }
}

.mb-3.mb-0 {
  margin-bottom: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0 !important;
}

@media print {
  .page-content {
    margin-left: 0 !important;
  }
  .no_print {
    display: none !important;
  }
  .unsetPrintHeight {
    height: unset;
  }
  .page_layout {
    box-shadow: none !important;
    margin-bottom: 0 !important;
    height: unset !important;
  }
  .page_section {
    padding: 0 !important;
  }
}
.has_x_scroll {
  overflow: auto;
  max-height: calc(100vh - 200px);
  width: 100%;
}

.MuiMenuItem-root {
  font-family: unset !important;
}

.frame_style_scroll {
  max-height: calc(100vh - 550px);
  overflow-y: auto;
  margin-right: -6px;
  padding-right: 6px;
}

.rdt_Table .rdt_TableCell,
.rdt_Table .rdt_TableCol {
  padding: 5px 5px !important;
}

.rdt_TableHeadRow {
  background-color: rgba(var(--theme-blue-rgb), 0.1) !important;
  border-bottom: none !important;
  min-height: 40px !important;
}
.rdt_TableHeadRow > div {
  font-size: 0.8125rem !important;
  font-weight: 600 !important;
  white-space: nowrap !important;
  color: #495057 !important;
}

.rdt_TableCol_Sortable:hover,
.rdt_TableCol_Sortable span:first-child {
  opacity: 1 !important;
}

.profile_link {
  display: flex;
  flex-direction: column;
}
.profile_link .quote_list {
  flex: 1;
}
.profile_link .rdt_TableBody {
  height: auto;
  overflow: hidden;
}

.custom_datatable thead {
  background-color: rgba(var(--theme-blue-rgb), 0.1) !important;
}
.custom_datatable.oversize_table .rdt_TableHeadRow > div:first-child,
.custom_datatable.oversize_table .rdt_TableRow > div:first-child {
  min-width: 250px;
}
.custom_datatable.oversize_table .rdt_TableHeadRow > div:not(:first-child),
.custom_datatable.oversize_table .rdt_TableRow > div:not(:first-child) {
  justify-content: center;
}
.custom_datatable.oversize_table .rdt_TableCell div:first-child {
  white-space: normal;
}

.rdt_TableRow {
  border-bottom: none !important;
  min-height: 40px !important;
}
.rdt_TableRow:hover {
  background-color: #efefef;
}

.layout_2 .page-content {
  margin-left: 0 !important;
  padding: 18px !important;
}
.layout_2 .page-content .left_content {
  min-height: calc(100vh - 92.34px);
}
.layout_2 .page-content .left_content > .card {
  max-height: calc(100vh - 92.34px);
}
.layout_2 #page-topbar {
  display: none !important;
}
.layout_2 .vertical-menu {
  display: none !important;
}

.dropdown-menu {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.dropdown-menu.body_dropdown {
  max-width: -moz-fit-content;
  max-width: fit-content;
  transform: none !important;
  right: 65px !important;
  top: 150px !important;
}
.dropdown-menu.body_dropdown.has_scroll {
  max-height: 200px;
  overflow-y: auto;
}
.dropdown-menu.body_dropdown.hardware_list {
  top: 350px !important;
}
.dropdown-menu.body_dropdown.panel_hardware_list {
  top: 210px !important;
}
.dropdown-menu.full_height {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  min-width: 200px;
}

.apertures_check .MuiCheckbox-root {
  margin: 0 !important;
  padding-right: 10px !important;
}
.apertures_check .MuiCheckbox-root .MuiIconButton-label {
  width: 20px;
  height: 20px;
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid var(--theme-blue);
  background: rgba(var(--theme-blue-rgb, 0.6));
  border-radius: 2px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.apertures_check .MuiCheckbox-root.Mui-checked ~ .MuiCheckbox-root .MuiIconButton-label {
  background: #ffffff;
  border-color: var(--bs-border-color);
}
.apertures_check .MuiCheckbox-root.Mui-checked .MuiIconButton-label {
  border-color: var(--theme-blue) !important;
  background: var(--theme-blue) !important;
}
.apertures_check .MuiCheckbox-root svg {
  display: none;
}
.apertures_check:hover .MuiCheckbox-root .MuiIconButton-label {
  border-color: var(--theme-blue) !important;
  background: var(--theme-blue) !important;
}
.apertures_check:hover .MuiCheckbox-root:hover ~ .MuiCheckbox-root .MuiIconButton-label {
  background: #ffffff !important;
  border-color: var(--bs-border-color) !important;
}

.btn.ref_btn {
  border: none;
  padding: 0;
  width: 32px;
  height: 32px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important;
}
.btn.ref_btn.btn-light {
  background: #ffffff;
  color: var(--bs-body-color);
}
.btn.ref_btn.btn-primary {
  color: #ffffff;
  border-color: var(--theme-blue);
  background: var(--theme-blue);
}
.btn.ref_btn.btn-primary svg {
  color: #ffffff !important;
}
.btn.ref_btn.lock_btn {
  border: 1px solid transparent;
  width: 18px;
  height: 18px;
  z-index: 1;
  top: -12px;
  right: -12px;
  color: #989898;
}
.btn.ref_btn.lock_btn.is_left {
  transform: rotate(-90deg);
}
.btn.ref_btn.lock_btn.btn-outline-danger {
  color: #f46a6a !important;
  border-color: #f46a6a !important;
  background: #ffffff !important;
}
.btn.ref_btn.type_2 {
  height: 24px;
  border-radius: 4px !important;
  font-size: 13px;
  padding: 0px 10px !important;
  width: auto;
  background: var(--theme-blue);
  color: #fff;
  border: none;
  z-index: 2;
  box-shadow: none !important;
}
.btn.ref_btn.type_2 span {
  line-height: 1;
  margin-top: 2px;
}

.btn_parent.is_left {
  transform: translateX(-8px) rotate(90deg);
}
.btn_parent.ref_sq_left {
  transform: rotate(-90deg) translateY(-5px);
}

.pricing_table tr td {
  padding: 0.5rem 0.25rem;
}
.pricing_table tr td:first-child {
  min-width: 30%;
  max-width: 160px;
}
.pricing_table tr td:nth-child(2) {
  min-width: 50%;
  max-width: 250px;
}
.pricing_table tr td:nth-child(3) {
  min-width: 20%;
  max-width: 100px;
}

.td_p_2.table tr td {
  padding: 0.5rem 0.25rem;
}

.model_hidden {
  opacity: 0;
}

.add_ancillary_btn > button {
  outline: 0 !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: 500;
}
.add_ancillary_btn > button:hover {
  background: rgba(var(--theme-blue-rgb), 0.1);
}
.add_ancillary_btn > button:nth-child(2)::before {
  content: "";
  position: absolute;
  left: 0;
  width: 1.5px;
  height: 80%;
  top: 10%;
  background-color: var(--bs-border-color);
}

.ref_btn_wrap {
  position: fixed;
  top: 22.5px;
  left: 100px;
}

.designer-right-menu .form-group.mb-2.mb-3 {
  margin-bottom: 1rem !important;
}

.glaze_img {
  position: absolute;
  width: 90% !important;
  height: 90% !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
  margin: 5%;
}

.manufacturing_scroll {
  overflow-y: auto;
  max-height: calc(100vh - 500px);
}

.style_svg svg path {
  color: #000000 !important;
  stroke: #000000 !important;
}

.visibility_on,
.visibility_off {
  display: none;
}

.quote_edit_form .scroll_area {
  min-width: 280px;
  max-height: calc(100vh - 385px);
  overflow-y: auto;
  margin-right: -15px;
  padding-right: 10px;
}

.circle_cross {
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle_cross.box_right_top {
  position: absolute;
  right: -10px;
  top: -10px;
}

.custom_shadow .popover {
  --bs-popover-border-color: #e5e5e5;
  max-width: 250px;
}
.custom_shadow .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.custom_shadow .bs-popover-bottom > .popover-arrow::before {
  --bs-popover-arrow-border: #d5d5d5;
}

.popover-inner .dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.back_btn,
.designer_btn {
  padding: 0.35rem 0.75rem 0.35rem 0.45rem;
  color: #ffffff !important;
}

#elements {
  visibility: visible;
  opacity: 1;
  transition: 0.3s all ease-in-out;
}
#elements.hide {
  visibility: hidden;
  opacity: 0;
}

.main_layout.page_scroll .left_content.full .inside_scroll .quote_list {
  max-width: 100%;
  margin-bottom: 10px;
}
.main_layout.page_scroll .left_content.full .inside_scroll .quote_list .rdt_TableBody {
  min-height: 250px;
  max-height: calc(100vh - 392px);
  max-width: 100%;
}

.rdt_Pagination select {
  height: 24px !important;
}
.rdt_Pagination > div:last-child {
  gap: 5px;
}
.rdt_Pagination > div:last-child button {
  padding: 0;
  width: 35px;
  height: 35px;
  background: #fff;
  border: 1px solid var(--bs-border-color);
}
.rdt_Pagination > div:last-child button:not(:disabled):hover {
  background: var(--theme-blue);
  border-color: var(--theme-blue);
}
.rdt_Pagination > div:last-child button:not(:disabled):hover svg {
  fill: #ffffff;
}

.status_indicator {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-indi-color-a);
}
.status_indicator:after {
  content: "";
  background-color: var(--bg-indi-color);
  height: 10px;
  width: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
}

.model_wrap {
  height: calc(100vh - 112px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.model_wrap > div:first-child {
  max-height: 100vh;
}

.accordion_btn_custom {
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--bs-body-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--bs-body-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 16px;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  padding: 5px 0;
  font-size: 17px;
  min-height: 40px;
}

.saving_images .btn.ref_btn.type_2 {
  background: transparent;
  color: #000000;
  font-size: 15px;
}
.saving_images .btn.ref_btn.lock_btn {
  top: -8px;
  right: -10px;
  z-index: 4;
}
.saving_images .btn_parent.is_left {
  transform: translateX(-15px) rotate(90deg);
}
.saving_images .btn_parent.ref_sq_left {
  transform: rotate(-90deg) translate(-5px, -20px);
}
.saving_images .btn_parent.is_right {
  transform: translateX(15px);
}
.saving_images .btn_parent.ref_sq_top {
  transform: translateY(-14px);
}
.saving_images .btn_parent.is_bottom {
  transform: translate(-40%, 10px);
}
.saving_images.hide_lock .btn_parent.is_bottom {
  transform: translate(0, 10px);
}
.saving_images.hide_lock .lock_btn {
  display: none !important;
}/*# sourceMappingURL=custom.css.map */